import axios from "axios";
import { APIEndpoint, wowza_url} from "../UrlPaths";

var wowzaKey = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiI5NTU3NzEwZS0yYzhlLTQ1MDgtOTEwOS1hZWMxNTEwODAxY2UiLCJqdGkiOiI0OTA5NzViMDcyOWJmODk3MzRiMWFlODg0NGNmN2E4YzA5ZmNiMmUwZmVhZjBiYThlNWQ4Njc2Y2Y1OGJlMTE5ODg1NmMxN2UxMTE0ZWViOCIsImlhdCI6MTY3NzQ4NzkzMCwibmJmIjoxNjc3NDg3OTMwLCJleHAiOjIzMDg2Mzk5MzAsInN1YiI6Ik9VLTUwZDZjZTU3LTkyOGUtNDA0NC04NGUyLWY3NTcwNDdiMTAzZCJ9.IUgx2Hkw4c_e4UFRqAA_dVnVR-JpYPZqtqTtqLYNxJMr64F4nZMUllKNzv-HJ07Q9m2d4l8K3GlzSk45U3nojq0AvIJk7sNKkSr1ulVBZbyHXS3jGGANJRqGA7ForTZh_5cLkYVSy27R2tx1zyydHtkZSeYQnmDBU6MpPhv3SulwLAgZDuI2A_duaBU61DbAEZLh7VoSqnZ7rBIyzj_ZFCr5DDHQ4dQn31_JNmLtJ2x0RVSHvPWXmNCs0SyTua4TN9neXEZ8bHfILJHkh-sAO8_2CjcBWWNAmE4leP_QDLu8pkokMryvrd49b7JKaB6aRlkIt1ri_jWXe2PUOJsXlAqbSSMgaRE0BbwulCZ7vevjypIA9zYqn5m1GEMxdf7pXmL0rQbcoud5Fv6LYRQKrFHz_stBpIdVpa06VI9svYcurU0xbTl-UOMMLLWM7fk4iJyGx0VJ2jYq6ZtfDn5H1qyytQMhihyz0EetHZITGsWVNTQdFIgMnFVwAzPVuOn5ouqgXmAD4DwUExxff6J2NbFppMkhc9BgRTdPfA0Qa5pc-lbZL9QezZ6OdTNoBK2nuzNCP4scYf8ysNnzxgPTzdbPUTDvvdwE3d6KWVxCuYRNEa0-7wqqNs4KWSihW0voDz-fjbqHOoloFPY22q5F3H-aNKTz-lYakJkr5Ekpg7w'
const token = localStorage.getItem("win_access_token");

export async function getAllEvents() {
  try {
    const response = await axios.get(APIEndpoint + "/getAllEvents", {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data.data;
  } catch (error) {
    return [];
  }
}

export async function eventActions(data) {
  try {
    const response = await axios.post(APIEndpoint + "/storeEvents", data, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data;
  } catch (error) {
    return error;
  }
}

export async function getSingleEventData(data) {
  try {
    const response = await axios.post(
      `${APIEndpoint}/getSingleEventData`, data,
      { headers: { 'Authorization': `Bearer ${token}`} });

    return response.data;
  } catch (error) {
   
    return error;
  }
}

export async function createEventStream(data) {
  try {
    const response = await axios.post(`${wowza_url}/v1.9/live_streams`, data, {
      headers: { 'Authorization': `Bearer ${wowzaKey}` , 'Content-Type': 'application/json' }
    });
    return response;
  } catch (error) {
    return error;
  }
}

export async function storeStreamProjectData(data) {
  try {
    const response = await axios.post(
      `${APIEndpoint}/storeLiveStreamProject`, data,
      { headers: { 'Authorization': `Bearer ${token}`,'Content-Type': 'application/json'} });

    return response.data;
  } catch (error) {
   
    return error;
  }
}

export async function startLiveStream(streamID) {
  try {

    const  response  = await axios({
      method: 'put',
      url: wowza_url+"/v1.9/live_streams/"+streamID+"/start",
      headers: {'Authorization': `Bearer ${wowzaKey}`, 'Content-Type': 'application/json'}
  });

    return response;
  } catch (error) {
    return error;
  }
}

export async function storeStreamState(data) {
  try {
    const response = await axios.post(
      `${APIEndpoint}/storeStreamState`, data,
      { headers: { 'Authorization': `Bearer ${token}`,'Content-Type': 'application/json'} });

    return response.data;
  } catch (error) {
   
    return error;
  }
}

export async function endLiveStream(streamID) {
  try {

    const  response  = await axios({
      method: 'put',
      url: wowza_url+"/v1.9/live_streams/"+streamID+"/stop",
      headers: {'Authorization': `Bearer ${wowzaKey}`, 'Content-Type': 'application/json'}
  });

    return response;
  } catch (error) {
    return error;
  }
}

export async function getLiveStreamState(streamID) {
  try {

    const  response  = await axios({
      method: 'get',
      url: wowza_url+"/v1.9/live_streams/"+streamID+"/state",
      headers: {'Authorization': `Bearer ${wowzaKey}`, 'Content-Type': 'application/json'}
  });

    return response;
  } catch (error) {
    return error;
  }
}

export async function getLiveStream(streamID) {
  try {

    const  response  = await axios({
      method: 'get',
      url: wowza_url+"/v1.9/live_streams/"+streamID,
      headers: {'Authorization': `Bearer ${wowzaKey}`, 'Content-Type': 'application/json'}
  });

    return response;
  } catch (error) {
    return error;
  }
}

