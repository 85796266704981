// export const APIEndpoint = "https://admin.win365sports.com/api";
// export const displayUrl = "https://admin.win365sports.com";
// export const postImageUrl = "https://admin.win365sports.com";
// export const websiteUrl = "https://www.win365sports.com/";
// export const wowza_url = "https://api.video.wowza.com/api";

export const APIEndpoint = "https://admin.ln.archnix.dev/api";
export const displayUrl = "https://admin.ln.archnix.dev";
export const postImageUrl = "https://admin.ln.archnix.dev";
export const websiteUrl = "https://web.ln.archnix.dev/";
export const wowza_url = "https://api.video.wowza.com/api";
