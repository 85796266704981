import React, {useEffect, useState} from "react";
import {Link, useNavigate} from "react-router-dom";
import Swal from "sweetalert2";
import {Input, Switch, Table} from "antd";
import "antd/dist/antd.css";
import {CheckOutlined, CloseOutlined,} from "@ant-design/icons";
import {getAllMatchSeriesNews, seriesPostStatusChange} from "../services/MatchSeriesAPIServices";

function SeriesPost() {
    const navigate = useNavigate();
    const queryParameters = new URLSearchParams(window.location.search);
    const search = queryParameters.get("search");

    const spinner = document.getElementById("spinner");
    const [tableData, setTableData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [searchedText, setSearchedText] = useState("");

    const columns = [
        {
            key: "id",
            title: "ID",
            dataIndex: "id",
            sortDirections: ["descend"],
            hidden: true,
        },
        {
            key: "title",
            title: "Title",
            filteredValue: [searchedText],
            render: (record) => {
                return (
                    <>
                        <div className="flex">
                            <Link to={`/series-news/${record.id}`}>
                                <span>{record.title}</span>
                            </Link>
                            <br></br>
                            <small className="text-muted">{record.status}</small>
                        </div>
                    </>
                );
            },
            onFilter: (value, record) => {
                return (
                    record.title.toLowerCase().includes(value.toLowerCase()) ||
                    record.author.toLowerCase().includes(value.toLowerCase()) ||
                    record.name.toLowerCase().includes(value.toLowerCase()) ||
                    record.status.toLowerCase().includes(value.toLowerCase()) ||
                    record.updated_date.toLowerCase().includes(value.toLowerCase())
                );
            },
        },
        {
            key: "author",
            title: "Author",
            dataIndex: "author",
        },
        {
            key: "name",
            title: "Series",
            dataIndex: "name",
        },
        {
            key: "status",
            title: "Published",
            render: (record) => {
                return (
                    <>
                        {" "}
                        <div className="flex">
                            {record.status == "PUBLISHED" ? (
                                <Switch
                                    checkedChildren={<CheckOutlined/>}
                                    unCheckedChildren={<CloseOutlined/>}
                                    defaultChecked
                                    onClick={() => handleClick("DRAFT", record.id)}
                                />
                            ) : (
                                <Switch
                                    checkedChildren={<CheckOutlined/>}
                                    unCheckedChildren={<CloseOutlined/>}
                                    defaultUnChecked
                                    onClick={() => handleClick("PUBLISHED", record.id)}
                                />
                            )}
                        </div>
                    </>
                );
            },
        },
        {
            key: "updated_date",
            title: "Last Modified",
            dataIndex: "updated_date",
        },
        {
            key: "action",
            title: "Actions",
            render: (record) => {
                return (
                    <>
                        <div className="flex">
                            <Link to={`/series-news-edit/${record.id}`}>
                                <button className="badge bg-light-danger">
                                    <i class="ft-edit"></i>
                                </button>
                            </Link>
                        </div>
                    </>
                );
            },
        },
    ].filter((item) => !item.hidden);

    useEffect(() => {

        if (search != "" && search != null) {
            setSearchedText(search);
        }
        setLoading(true);

        getAllMatchSeriesNews().then((response) => {
            spinner.style.display = "none";
            setTableData(response);
            setLoading(false);
        });
    }, []);

    const handleClick = (status, postId) => {
        const data = {status, postId};

        seriesPostStatusChange(data).then((response) => {
            if (response.status_code == 1) {
                Swal.fire("Success", "" + response.message, "success");
            } else {
                Swal.fire("Warning", " " + response.message, "warning");
            }

            setLoading(true);

            getAllMatchSeriesNews().then((response) => {
                setTableData(response);
                setLoading(false);
            });
        });
    };

    return (
        <>
            <div className="row">
                <div className="col-8">
                    <h3>All Series News</h3>
                    <p style={{color: "grey"}}>
                        Create, edit, and manage the match series news on website.
                    </p>
                </div>
                <div className="col-4">
                  <button
                      type="button"
                      className="btn btn-secondary mb-3 d-block  float-left"
                      onClick={() => navigate("/matches")}
                  >
                    Go to Matches
                  </button>
                    <Link to="/new-series-post">
                        <button
                            type="button"
                            className="btn btn-primary mb-3 d-block  float-right"
                        >
                            Add new news
                        </button>
                    </Link>
                </div>
            </div>

            <section className="users-list-wrapper">
                <div className="users-list-table">
                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-content">
                                    <div className="card-body">
                                        <div className="col-xl-6">
                                            <Input.Search
                                                placeholder="Search here..."
                                                style={{margin: 10}}
                                                value={searchedText}
                                                onSearch={(value) => {
                                                    setSearchedText(value);
                                                }}
                                                onChange={(e) => {
                                                    setSearchedText(e.target.value);
                                                }}
                                            />
                                        </div>
                                        <div className="table-responsive">
                                            <Table
                                                loading={loading}
                                                dataSource={tableData}
                                                columns={columns}
                                                pagination={{
                                                    pageSize: 25,
                                                    showSizeChanger: true,
                                                    position: ["bottomCenter"],
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default SeriesPost;
