import React, { useEffect, useState, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { Button, DatePicker, Input, Modal, Radio, Space, Switch, TimePicker, Form, InputNumber, Popconfirm, Table, Typography, Select } from "antd";
import "antd/dist/antd.css";
import { CheckOutlined, CloseOutlined, } from "@ant-design/icons";
import {
    deleteSeriesData,
    getAllSeries,
    getAllThridPartySeries,
    getParentSeriesList, pointTableContent, storeSeriesActions, storeThirdPartyPointsData, updatePointsTableData
} from "../services/MatchSeriesAPIServices";
import { displayUrl, postImageUrl } from "../UrlPaths";
import $ from 'jquery';

const { Option } = Select;

function Series() {
    const navigate = useNavigate();
    const queryParameters = new URLSearchParams(window.location.search);
    const search = queryParameters.get("search");

    const inputRef = useRef();
    const spinner = document.getElementById("spinner");
    const [tableData, setTableData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [searchedText, setSearchedText] = useState("");
    const [modalTitle, setModalTitle] = useState("Add New Series");
    const [visible, setVisible] = useState(false);
    const [name, setName] = useState("");
    const [short_code, setShortCode] = useState("");
    const [slug, setSlug] = useState("");
    const [parent_id, setParentId] = useState("");
    const [parentSeriesList, setParentSeriesList] = useState([]);
    const [seriesId, setSeriesId] = useState("0");
    const [points_table, setPointsTable] = useState(0);
    const [pointTableModalTitle, setPointTableModalTitle] = useState("Add New Series");
    const [ptMVisible, setPtMVisible] = useState(false);
    const [form] = Form.useForm();
    const [data, setData] = useState([]);
    const [editingKey, setEditingKey] = useState('');
    const [image, setImage] = useState("");
    const [existedImage, setExistedImage] = useState("");
    const [imageLabel, setImageLabel] = useState("Image");
    const [thirdPartySeriesList, setThirdPartySeriesList] = useState([]);
    const [thirdPartySeriesId, setThirdPartySeriesId] = useState("");
    const [source_id, setSourceId] = useState("");
    const [series_id, setUpadteSeriesId] = useState("");

    const columns = [
        {
            key: "id",
            title: "ID",
            dataIndex: "id",
            sortDirections: ["descend"],
            hidden: true,
        },
        {
            key: "name",
            title: "Name",
            filteredValue: [searchedText],
            render: (record) => {
                return (
                    <>
                        <div className="flex">
                            {record.parent_id == null ? (
                                <b><span>{record.name}</span></b>
                            ) : (
                                <span>{record.name}</span>
                            )}

                        </div>
                    </>
                );
            },
            onFilter: (value, record) => {
                return (
                    record.name.toLowerCase().includes(value.toLowerCase()) ||
                    record.short_code.toLowerCase().includes(value.toLowerCase())
                );
            },
        },
        {
            key: "short_code",
            title: "Short name",
            dataIndex: "short_code",
        },
        {
            key: "action",
            title: "",
            render: (record) => {
                return (
                    <>
                        <div className="d-flex">
                            <button
                                type="submit"
                                className="btn btn-sm bg-light-danger"
                                onClick={() => deleteSeries(record.id)}
                            >
                                <i class="fa fa-trash"></i>
                            </button>

                            &nbsp;  <button className="btn btn-sm bg-light-warning" onClick={(e) => handleEditMatchForm(record)}>
                                <i className="ft-edit"></i>
                            </button>



                            {record.points_table == 1 ? (<>
                                &nbsp;
                                <button className="btn btn-sm bg-light-info" style={{ marginLeft: 'auto' }} onClick={(e) => openSeriesPointTable(record)}>
                                    <i className="ft-edit"></i> Points Table
                                </button>
                            </>) : (<></>)}

                        </div>
                    </>
                );
            },
        },
    ].filter((item) => !item.hidden);


    const EditableCell = ({
        editing,
        dataIndex,
        title,
        inputType,
        record,
        index,
        children,
        ...restProps
    }) => {
        const inputNode = inputType === 'number' ? <InputNumber /> : <Input />;
        return (
            <td {...restProps}>
                {editing ? (
                    <Form.Item
                        name={dataIndex}
                        style={{
                            margin: 0,
                        }}
                        rules={[
                            {
                                required: true,
                                message: `Please Input ${title}!`,
                            },
                        ]}
                    >
                        {inputNode}
                    </Form.Item>
                ) : (
                    children
                )}
            </td>
        );
    };


    useEffect(() => {

        if (search != "" && search != null) {
            setSearchedText(search);
        }
        dataLoad();



    }, []);

    function dataLoad() {
        setLoading(true);
        getAllSeries().then((response) => {
            spinner.style.display = "none";
            setTableData(response);
            setLoading(false);
        });
        getParentSeriesList().then((response) => {
            setParentSeriesList(response);
        });
        getAllThridPartySeries().then((response) => {

            response.status_code == 1 ? setThirdPartySeriesList(response.data) : setThirdPartySeriesList([]);
        });
    }

    const handleNewMatchForm = () => {
        setName("");
        setShortCode("");
        setParentId("");
        setSlug("");
        setSeriesId("0");
        setPointsTable(0)
        if (image) {
            inputRef.current.value = "";
        }
        setExistedImage("");
        setImageLabel("Image");
        setModalTitle("Add New Series");
        setVisible(true);
    }

    const handleSubmit = (e) => {
     
        // const data ={ seriesId,name,short_code,parent_id,slug,points_table}
        if( parent_id != "" && thirdPartySeriesId == "" ){
            Swal.fire("Warning", "Need to select a source for scores", "warning");
        }else{
            const data = new FormData();
            data.append("seriesId", seriesId);
            data.append("name", name);
            data.append("short_code", short_code);
            data.append("parent_id", parent_id);
            data.append("slug", slug);
            data.append("points_table", points_table);
            data.append("image", image);
            data.append("cricbuzz_series_id", thirdPartySeriesId);
    
            storeSeriesActions(data).then((response) => {
                if (response.status_code == 1) {
                    Swal.fire("Success", " " + response.message, "success");
                    setImage("");
                    inputRef.current.value = "";
                    setVisible(false);
                    dataLoad();
                } else {
                    Swal.fire("Warning", " " + response.message, "warning");
                }
            });
        }


    }
    const autoFillSlug = () => {
        var nameSlug = name.replaceAll(" ", "-");
        setSlug(nameSlug.toLowerCase());
    };

    const handleEditMatchForm = (data) => {
        setName(data.name);
        setShortCode(data.short_code);
        setParentId(data.parent_id == null ? "" : data.parent_id );
        setSlug(data.slug);
        setSeriesId(data.id);
        setPointsTable(data.points_table);
        setExistedImage(data.series_image);
        setThirdPartySeriesId(data.cricbuzz_series_id == null ? "" : data.cricbuzz_series_id);
        setImageLabel("Replace Image");
        setModalTitle("Edit Series - " + data.name);
        setVisible(true);
    }

    const onChangePointsTable = (e) => {
        setPointsTable(e.target.value);
    };

    const openSeriesPointTable = (data) => {
        const data_json = { id: data.id };
        setUpadteSeriesId(data.id);
        setSourceId(data.cricbuzz_series_id);
        setPointTableModalTitle("Points Table - " + data.name);
        loadPointsTableRecord(data_json)
        setPtMVisible(true);
    }

    function loadPointsTableRecord(json_series_id){

        pointTableContent(json_series_id).then((response) => {
            setData(response.data.teamList)
         
        });

    }



    const isEditing = (record) => record.id === editingKey;
    const edit = (record) => {

        form.setFieldsValue({
            played_matches_count: '',
            won_count: '',
            lost_count: '',
            tied_count: '',
            abandoned_count: '',
            points_awarded_count: '',
            net_run_rate: '',
            ...record,
        });
        setEditingKey(record.id);
    };
    const cancel = () => {
        setEditingKey('');
    };
    const save = async (key) => {
        try {
            const row = await form.validateFields();
            const newData = [...data];
            const index = newData.findIndex((item) => key === item.id);


            if (index > -1) {
                const item = newData[index];
                newData.splice(index, 1, {
                    ...item,
                    ...row,
                });
                setData(newData);
                // console.log('edit item', newData[index]);

                updatePointsTableData(newData[index]).then((response) => {
                    if (response.status_code == 1) {
                        Swal.fire("Success", " " + response.message, "success");
                    } else {
                        Swal.fire("Warning", " " + response.message, "warning");
                    }
                });
                setEditingKey('');

            } else {
                newData.push(row);
                setData(newData);
                setEditingKey('');
            }
        } catch (errInfo) {
            console.log('Validate Failed:', errInfo);
        }
    };
    const columns2 = [
        {
            title: 'Team',
            width: '25%',
            editable: false,
            render: (record) => {
                return (
                    <>
                        <div className="flex">

                        {record.row_type.includes('divider') ? (<>
                        <b><span>{record.name_official}</span></b>
                        </>):(<>
                        <b><span>
                        <img src={postImageUrl+'/'+record.flag_path} width={25} style={{borderRadius:'0px'}} />
                        &nbsp;{record.name_official} </span></b>
                        </>)}
                
                        </div>
                    </>
                );
            }
        },
        {
            title: 'M',
            dataIndex: 'played_matches_count',
            editable: true,
        },
        {
            title: 'W',
            dataIndex: 'won_count',
            editable: true,
        },
        {
            title: 'L',
            dataIndex: 'lost_count',
            editable: true,
        },
        {
            title: 'T',
            dataIndex: 'tied_count',
            editable: true,
        },
        {
            title: 'N/R',
            dataIndex: 'abandoned_count',
            editable: true,
        },
        {
            title: 'PT',
            dataIndex: 'points_awarded_count',
            editable: true,
        },
        {
            title: 'NRR',
            dataIndex: 'net_run_rate',
            editable: true,
        },
        // {
        //     title: 'operation',
        //     dataIndex: 'operation',
        //     render: (_, record) => {
        //         const editable = isEditing(record);
        //         return editable ? (
        //             <span>
        //                 <Typography.Link
        //                     onClick={() => save(record.id)}
        //                     style={{
        //                         marginRight: 8,
        //                     }}
        //                 >
        //                     Save
        //                 </Typography.Link>
        //                 <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
        //                     <a>Cancel</a>
        //                 </Popconfirm>
        //             </span>
        //         ) : (
        //             <Typography.Link disabled={editingKey !== ''} onClick={() => edit(record)}>
        //                 Edit
        //             </Typography.Link>
        //         );
        //     },
        // },
    ];
    const mergedColumns = columns2.map((col) => {
        if (!col.editable) {
            return col;
        }
        return {
            ...col,
            onCell: (record) => ({
                record,
                inputType: 'number',
                dataIndex: col.dataIndex,
                title: col.title,
                editing: isEditing(record),
            }),
        };
    });

    const deleteSeries = (series_id) => {
        const dataDelete = { series_id }
        deleteSeriesData(dataDelete).then((response) => {
            if (response.status_code == 1) {
                Swal.fire("Success", " " + response.message, "success");
                dataLoad();
            } else {
                Swal.fire("Warning", " " + response.message, "warning");
            }
        });
    };

    const onChangeSeries = (value) => {
       
        setThirdPartySeriesId(value);
    };
    const onSearchSeries = (value) => {

    };

    // Filter `option.label` match the user type `input`
    const filterOptionSeries = (input, option) =>
        (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

    const updateThirdPartyPointsData = () => {

        $('#btnPTDataUpdate').text('Processing...')
        $("#btnPTDataUpdate").prop("disabled",true);

        const updatePP = {series_id,source_id};
        storeThirdPartyPointsData(updatePP).then((response) => {
            if (response.status_code == 1) {
                Swal.fire("Success", " " + response.message, "success");
                let json_id_record = {id:series_id};
                loadPointsTableRecord(json_id_record);
            } else {
                Swal.fire("Warning", " " + response.message, "warning");
            }
            $('#btnPTDataUpdate').text('Update Points Table')
            $("#btnPTDataUpdate").prop("disabled",false);
        });
    }

    return (
        <>
            <div className="row">
                <div className="col-8">
                    <h3>Series</h3>
                    <p style={{ color: "grey" }}>
                        Create, edit, and manage the match series on website.
                    </p>
                </div>
                <div className="col-4">
                    <button
                        type="button"
                        className="btn btn-primary mb-3 d-block  float-right"
                        onClick={(e) => handleNewMatchForm(e)}
                    >
                        Add New Series
                    </button>
                </div>
            </div>

            <section className="users-list-wrapper">
                <div className="users-list-table">
                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-content">
                                    <div className="card-body">
                                        <div className="col-xl-6">
                                            <Input.Search
                                                placeholder="Search here..."
                                                style={{ margin: 10 }}
                                                value={searchedText}
                                                onSearch={(value) => {
                                                    setSearchedText(value);
                                                }}
                                                onChange={(e) => {
                                                    setSearchedText(e.target.value);
                                                }}
                                            />
                                        </div>
                                        <div className="table-responsive">
                                            <Table
                                                loading={loading}
                                                dataSource={tableData}
                                                columns={columns}
                                                pagination={{
                                                    pageSize: 15,
                                                    showSizeChanger: true,
                                                    position: ["bottomCenter"],
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Modal
                title={modalTitle}
                maskClosable={false}
                visible={visible}
                onCancel={() => setVisible(false)}
                onOk={(e) => {
                    handleSubmit(e);
                }}
                footer={[
                    <Button key="back" onClick={() => setVisible(false)}> Cancel </Button>,
                    <Button key="submit" type="primary" onClick={handleSubmit}> Save </Button>,
                ]}
            >
                <div className="row">
                    <div className="col-12">
                        <label className="form-label">
                            Name <span style={{ color: "red" }}>*</span>
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            placeholder="Enter series name"
                            onKeyUp={autoFillSlug}
                        />
                        <span className="text-muted">slug: {slug}</span>
                    </div>
                    <div className="col-7">
                        <label className="form-label">
                            Display Name <span style={{ color: "red" }}>*</span>
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            value={short_code}
                            onChange={(e) => setShortCode(e.target.value)}
                            placeholder="Enter series short code"
                        />
                    </div>
                    <div className="col-md-5 col-12">
                        <label className="form-label">
                            Points table available?
                        </label>
                        <br />
                        <Radio.Group onChange={onChangePointsTable} value={points_table}>
                            <Space direction="horizontal">
                                <Radio value={1}>Yes</Radio>
                                <Radio value={0}>No</Radio>
                            </Space>
                        </Radio.Group>
                    </div>
                    <div className="col-7">
                        <label className="form-label">
                            Main Series <span style={{ color: "red" }}>*</span>
                        </label>
                        <select className="form-control" value={parent_id} onChange={(e) => setParentId(e.target.value)}>
                            <option value="" selected={true} >This series is a main series</option>
                            {parentSeriesList.map((item, i) => {
                                return (
                                    <>
                                        <option value={item.id} key={i}>
                                            {item.name}
                                        </option>
                                    </>
                                );
                            })}
                        </select>
                    </div>
                    <div className="col-md-5 col-5">
                        <label className="form-label">
                            {imageLabel}
                        </label>
                        <input
                            type="file"
                            name="document"
                            accept="image/png"
                            className="form-control"
                            ref={inputRef}
                            onChange={(e) =>
                                setImage(inputRef.current.files[0])
                            }
                        />
                        <small className="text-muted">*only use the png format image</small>
                    </div>
                    <div className="col-md-12 col-12">
                        <label className="form-label">
                        Score source
                        </label>
                        <Select
                            showSearch
                            placeholder="Select a series"
                            style={{ width: '100%' }}
                            onChange={onChangeSeries}
                            onSearch={onSearchSeries}
                            filterOption={filterOptionSeries}
                            value={thirdPartySeriesId}
                        >
                            {thirdPartySeriesList.map(item => (<>
                                {item.our_series_id == null ? (<>
                                    <Option key={item.cricbuzz_id} value={item.cricbuzz_id} label={item.name} disabled={false}>
                                        {item.name} - {item.month}
                                    </Option>
                                </>) : (<>  <Option key={item.cricbuzz_id} value={item.cricbuzz_id} label={item.name} disabled={true}>
                                    {item.name} - {item.month}
                                </Option></>)}

                            </>))}
                        </Select>
                    </div>

                    {existedImage ? (<>
                        <div className="col-4 text-center">
                            <strong className="form-label text-bold">Current Image</strong><br />
                            <img alt={'series-image'} className="img-fluid" width={100} src={`${displayUrl}/${existedImage}`} />
                        </div>
                    </>) : (<></>)}
                </div>
            </Modal>


            <Modal
                title={pointTableModalTitle}
                maskClosable={false}
                width={900}
                visible={ptMVisible}
                onCancel={() => setPtMVisible(false)}
                footer={[
                    <Button key="back" onClick={() => setPtMVisible(false)}> Close </Button>,
                ]}
            >
                <div className="row">
                    <div className="col-12 float-right">
                    <button
                        type="button"
                        id="btnPTDataUpdate"
                        className="btn btn-sm btn-primary mb-1 d-block float-right"
                        onClick={(e) => updateThirdPartyPointsData(e)}
                    >
                       Update Points Table
                    </button>
                    </div>
                    <div className="col-12">
                        <Form form={form} component={false}>
                            <Table
                                components={{
                                    body: {
                                        cell: EditableCell,
                                    },
                                }}
                                rowClassName={(record, index) => ((record.row_type==='divider' ? 'p-table-grp' : ''))}
                                size="middle"
                                bordered
                                dataSource={data}
                                columns={mergedColumns}
                                // rowClassName="editable-row"
                                // pagination={{
                                //     onChange: cancel,
                                // }}
                                pagination={{
                                    pageSize: 12,
                                    position: ["bottomCenter"],
                                }}
                            />
                        </Form>
                    </div>
                </div>
            </Modal>
        </>
    );
}

export default Series;
