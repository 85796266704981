import axios from "axios";
import { APIEndpoint } from "../UrlPaths";
const token = localStorage.getItem("win_access_token");

export async function getAllUsers() {
  try {
    const response = await axios.get(APIEndpoint + "/getAllUsers", {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data.data;
  } catch (error) {
    return [];
  }
}

export async function userActions(data) {
  try {
    const response = await axios.post(APIEndpoint + "/storeUser", data, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data;
  } catch (error) {
    return error;
  }
}

export async function getActiveUserRoles() {
  try {
    const response = await axios.get(APIEndpoint + "/getAllUserRoles", {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data.data;
  } catch (error) {
    return [];
  }
}
