import axios from "axios";
import { APIEndpoint } from '../UrlPaths';


export async function resetMailSending(data) {
  try {
    const response = await axios.post(
      APIEndpoint+'/password/resetMail',data,
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return error;
  }
}

export async function resetPasswordAPI(data) {
  try {
    const response = await axios.post(
      `${APIEndpoint}/password/reset`,data,
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return error;
  }
}