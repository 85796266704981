import { useParams, Link, useNavigate } from "react-router-dom";
import React, { useState, useEffect, useRef, useCallback } from "react";

import { APIEndpoint, postImageUrl } from "../UrlPaths";
import Swal from "sweetalert2";

import { Select, Modal,DatePicker } from "antd";
import moment from "moment";
import dayjs from 'dayjs';
import BundledEditor from "../components/BundledEditor";
import $ from 'jquery';
import Cropper from 'react-easy-crop'
import getCroppedImg from "../components/cropImage";
import "../components/cropper.css";

import { withNamespaces } from "react-i18next";
import {
  getMatchSeriesPostContent,
  getSeriesList,
  seriesPostActions,
  uploadSeriesMainImage
} from "../services/MatchSeriesAPIServices";

function SeriesPostEdit({ t }) {
  const { id } = useParams();
  const all_post_navigate = useNavigate();
  moment().format("DD/MM/YYYY HH:mm");
  //  moment.tz.setDefault("Asia/Colombo");
  const inputRef = useRef();
  const { Option } = Select;
  const spinner = document.getElementById("spinner");
  const [seriesList, setSeriesList] = useState([]);
  const [title, setTitle] = useState("");
  const [series_id, setSeriesId] = useState("");
  const [post_content, setPostContent] = useState("");
  const [type, setPostType] = useState("");
  const [status, setStatus] = useState("");
  const [postId, setPostId] = useState(0);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [language, setLanguage] = useState("");

  const [visible, setVisible] = useState(false);
  const [modalTitle, setModalTitle] = useState("Image Crop");
  const [main_image_path, setMainImagePath] = useState("");
  const [uploadedImage, setUploadedImage] = useState("");
  const [publishDate, setPublishDate] = useState(moment().format("DD/MM/YYYY HH:mm"));
  const [cropB, setCropB] = useState({ x: 0, y: 0 })
  const [zoom, setZoom] = useState(1)
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [post_content_inital, setPostContentInital] = useState("");
  const [style, setStyle] = useState({  containerStyle: { top: "0",maxWidth:"100%", maxHeight:"100%", overflow: "hidden" },
  mediaStyle: { width: "100%", display: "block" }, 
             });

  var image64Path = "";

  useEffect(() => {
    getSeriesList().then((response) => {
      setSeriesList(response);
      spinner.style.display = "none";
    });
    

    var data = { id };
    getMatchSeriesPostContent(data).then((response) => {
      // console.log(moment(response.data.published_date).format("DD/MM/YYYY kk:mm"));
      setPublishDate(response.data.published_date);
      $('#previous_published_date').text(moment(response.data.published_date).format("DD/MM/YYYY kk:mm"))
      setTitle(response.data.title);
      setSeriesId(response.data.series_id);
      setStatus(response.data.status);
      setPostContent(response.data.post_content);
      setPostContentInital(response.data.post_content);
      setPostId(id);
      setUploadedImage(`${postImageUrl}` + "/" + response.data.main_image_path);
      setMainImagePath(response.data.main_image_path);
      console.log(post_content_inital);
    });
  }, []);

  const onChangeStartDate = (date, dateString) => {
    // console.log(dateString);
    // console.log(date);
    setPublishDate(dateString);
   
  };
  const editorRef = useRef(null);
  const log = () => {
    if (editorRef.current) {
      setPostContent(editorRef.current.getContent());
    }
  };

  //-------------------------------------------
  //save the image that used to be crop
  const [image, setImage] = useState(null);
  //change the aspect ratio of crop tool as you preferred
  const [crop, setCrop] = useState({ aspect: 16 / 9 });
  //save the resulted image
  const [result, setResult] = useState(null);

  const [srcImg, setSrcImg] = useState(null);

  const handleImage = async (event) => {
    let img = new Image()
    img.src = window.URL.createObjectURL(event.target.files[0])
    img.onload = () => {
      console.log(img.width);
        if(img.width >= 800 && img.height >= 450 ){
          setSrcImg(URL.createObjectURL(event.target.files[0]));
          setResult("");
          setVisible(true);
            return true;
        }else{
          Swal.fire("Warning", `Sorry, this image doesn't look like the size we wanted. It's 
          ${img.width} x ${img.height} but we require at least 800 x 450 image.`, "warning");
          return true;
        }
      
    }
  };


  useEffect(() => {}, [srcImg]);

  const example_image_upload_handler = (blobInfo, progress) =>
    new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.withCredentials = false;
      xhr.open("POST", `${APIEndpoint}` + "/uplaodImageFromEditor");

      xhr.upload.onprogress = (e) => {
        progress((e.loaded / e.total) * 100);
      };

      xhr.onload = () => {
        if (xhr.status < 200 || xhr.status >= 300) {
          reject("HTTP Error: " + xhr.status);
          return;
        }

        const json = JSON.parse(xhr.responseText);

        resolve(`${postImageUrl}` + "/" + json.message);
      };

      xhr.onerror = () => {
        reject(
          "Image upload failed due to a XHR Transport error. Code: " +
            xhr.status
        );
      };

      const formData = new FormData();
      formData.append("file", blobInfo.blob(), blobInfo.filename());

      xhr.send(formData);
    });

  //-----------------------------------------------------
  //----------------- new code ------
  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);
  

   const convertBlobToBase64 = (blob) => new Promise((resolve, reject) => {
    const reader = new FileReader;
    reader.onerror = reject;
    reader.onload = () => {
        resolve(reader.result);
    };
    reader.readAsDataURL(blob);
});

  const onUploadCrop = useCallback(async (e) => {

    if (croppedAreaPixels) {
      const [croppedBlob, croppedUri] = await getCroppedImg(
        srcImg,
        croppedAreaPixels
      );
      const base64String = await convertBlobToBase64(croppedBlob);

      setResult(base64String);
      image64Path = base64String;
      setTimeout(handleSubmit(e), 2500);
     
    }
  }, [srcImg, croppedAreaPixels]);

//----------------------

  const handlePostSave = (e) => {
    e.preventDefault();

      const data = {
        title,
        series_id,
        post_content,
        postId,
        publishDate,
        main_image_path,
        status,
      };

      setButtonLoading(true);
      seriesPostActions(data).then((response) => {
        if (response.status_code == 1) {
          Swal.fire("Success", "" + response.message, "success");
          setTitle("");
          setPostContent("");
          all_post_navigate("/series-news");
          all_post_navigate(0);
        } else {
          Swal.fire("Warning", " " + response.message, "warning");
        }
        setButtonLoading(false);
      });

  };


  const handleSubmit = (e) => {
    e.preventDefault();

    if (image64Path == "" || image64Path == null) {
      Swal.fire("Warning", t("crop_image"), "warning");
    } else {
      const data = new FormData();
      data.append("file", image64Path);
      uploadSeriesMainImage(data).then((response) => {
        if (response.status_code == 1) {
          Swal.fire("Success", t("image_upload_success"), "success");
          setMainImagePath(response.message);
          setUploadedImage(`${postImageUrl}` + "/" + response.message);
          setVisible(false);
        } else {
          Swal.fire("Warning", " " + response.message, "warning");
        }
      });
    }
  };

  const popupDocument = (url) => {
    let win = window.open(
      url,
      null,
      "popup,width=500,height=600,left=0,right=0"
    );
  };

  return (
    <>
      <div className="row">
        <div className="col-8">
          <h3> Edit Post </h3>
        </div>
      </div>
      <section id="action-form-layout">
        <div className="row match-height">
          <div className="col-9">
            <div className="card">
              <div className="card-header">
                <div className="form-group float-left">
                  <b>
                    <Link to={`/series-news/` + id}>
                      <i class="ft-chevrons-left"></i>
                      <span for="basic-form-8"> View Post </span>
                    </Link>
                  </b>
                </div>

                {/* <h4 className="card-title">Form Actions</h4> */}
              </div>
              <div className="card-content">
                <div className="card-body">
                  <div className="form-row">
                    <div className="col-md-12 col-12">
                      <div className="form-group position-relative">
                        <label for="title">
                          Title <span style={{ color: "red" }}>*</span>
                        </label>
                        <input
                          type="text"
                          id="title"
                          className="form-control"
                          placeholder="Title"
                          name="title"
                          value={title}
                          onChange={(e) => setTitle(e.target.value)}
                          required
                        />
                      </div>
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="col-md-6 col-12">
                      <div className="form-group position-relative">
                        <label for="form-action-3">
                          Series <span style={{ color: "red" }}>*</span>
                        </label>
                        <select
                          className="form-control"
                          value={series_id}
                          onChange={(e) => setSeriesId(e.target.value)}
                          required
                        >
                          <option value="" disabled>
                            select series
                          </option>
                          {seriesList.map((item, i) => {
                            return (
                              <>
                                  <option value={item.id} key={i}>
                                    {item.name}
                                  </option>
                              </>
                            );
                          })}
                        </select>
                      </div>
                    </div>
                    <div className="col-md-6 col-12">
                     
                    </div>
                  </div>
                  <div className="form-group">
                    <label for="form-action-7">
                      Post Content <span style={{ color: "red" }}>*</span>
                    </label>
                    <BundledEditor
                      onInit={(evt, editor) => (editorRef.current = editor)}
                      onChange={log}
                      initialValue={post_content_inital}
                      init={{
                        height: 500,
                        menubar: false,
                        plugins: [
                          "advlist",
                          "anchor",
                          "autolink",
                          "image",
                          "link",
                          "lists",
                          "searchreplace",
                          "table",
                          "media",
                          "mediaembed",
                          "wordcount",
                        ],
                        toolbar:
                          "blocks | " +
                          "bold italic forecolor | alignleft aligncenter " +
                          "alignright alignjustify | bullist numlist image | media link table |" +
                          "outdent indent",
                        toolbar_sticky: true,
                        content_style:
                          "body { font-family:Roboto, sans-serif; font-size:14px }",
                        a11y_advanced_options: true,
                        image_advtab: true,
                        image_uploadtab: true,
                        automatic_uploads: true,
                        relative_urls: false,
                        remove_script_host: false,
                        // mediaembed_service_url: 'https://admin.ln.archnix.dev/',
                        images_upload_handler: example_image_upload_handler,
                        // video_template_callback: function(data) {
                        //   console.log(data.source);
                        //   if (data.source.includes('assets/videos/')) {
                        //     var embedHtml = '<video controls="controls" controlsList="nodownload" width="'+data.width+'" height="' + data.height + '"> <source src="https://admin.ln.archnix.dev/' + data.source + '" type="'+data.sourcemime+'"></video>';
                        //     return embedHtml;
                        //   } else {
                        //     return "";
                        //   }
                        // }
                      }}
                    />
                  </div>
                  <hr />
                </div>
              </div>
            </div>
          </div>
          <div className="col-3">
            {/* <div className="card"> */}
            <div className="row">
              <form
                onSubmit={(e) => {
                  handlePostSave(e);
                }}
              >
                <button
                  type="submit"
                  className="btn btn-warning float-left"
                  disabled={buttonLoading}
                >
                  Update
                </button>
              </form>
            </div>
            <br></br>
            <div className="row">
              <div className="col-12 col-md-7">
                <div className="form-group">
                  <label for="basic-form-8">Status & Visibility</label>
                  <select
                    id="status"
                    name="status"
                    className="form-control"
                    value={status}
                    onChange={(e) => setStatus(e.target.value)}
                    required
                  >
                    <option value="DRAFT">DRAFT</option>
                    <option value="PUBLISHED">PUBLISH</option>
                    <option value="TRASHED">TRASHED</option>
                  </select>
                </div>
              </div>
              <div className="col-12 col-md-12">
                <div className="form-group">
                  <label for="basic-form-8">
                   Publish Date <span style={{ color: "red" }}>* </span><span id="previous_published_date"></span>
                  </label>
                  <DatePicker 
                    // onChange={(value) =>
                    //   onChangeStartDate({ target: { value, name: "date" } })
                    // }
                    format={'DD/MM/YYYY HH:mm'}
                    onChange={onChangeStartDate}
                    disabledDate={(current) => current.valueOf() > Date.now()}
                    defaultValue={publishDate !== ""
                    ? ""
                    : moment()}
                   
                    allowClear={false}
                    />
                </div>
              </div>
              <div className="col-12 col-md-12">
                <div className="form-group">
                  <label for="basic-form-8">
                    Main Image Replace
                  </label>
                  {/* <ImageResize /> */}
                  {/* <DropZone/> */}
                  <input
                    type="file"
                    name="document"
                    accept="image/*"
                    className="form-control"
                    // ref={inputRef}
                    required
                    onChange={handleImage}
                    // onChange={(e) => setDocument(inputRef.current.files[0])}
                  />
                  <p className="text-muted mb-0 mt-1 mt-sm-0">
                    <small>*Allowed JPG, PNG or JPEG.</small>
                  </p>
                </div>
              </div>

              <div className="col-12 col-md-12">
                {uploadedImage && (
                  <>
                    <img
                      src={uploadedImage}
                      className="img-fluid"
                      width={200}
                      alt="cropped image"
                    />

                    <button
                      type="button"
                      id="popupBtnId"
                      class="btn btn-info bg-light-info"
                      onClick={() => popupDocument(`${uploadedImage}`)}
                    >
                      <i className="ft-eye"></i>
                    </button>
                  </>
                )}
              </div>
              {/* <div className="col-12 col-md-12 mt-3">
                <div className="form-group">
                  <b>
                    <Link to={`/post-revisions/${id}`}>
                      <span for="basic-form-8">REVISIONS </span>
                      <i class="ft-rotate-ccw"></i>
                    </Link>
                  </b>
                </div>
              </div> */}
            </div>
            {/* </div> */}
          </div>
        </div>
      </section>
      <Modal
        title={modalTitle}
        maskClosable={false}
        visible={visible}
        okText="Upload"
        width={750}
        onCancel={() => setVisible(false) /*ResetEditing() */}
        onOk={(e) => /*setVisible(false)*/ {
          onUploadCrop(e);
        }}
      >
      <div className="container-crop">
      <div className="container-cropper">
          {srcImg && (<>
                      <div className="cropper" >

                      <Cropper
                        image={srcImg}
                        crop={cropB}
                        zoom={zoom}
                        aspect={16 / 9}
                        onCropChange={setCropB}
                        onCropComplete={onCropComplete}
                        onZoomChange={setZoom}
                        // style={style}
                        showGrid={true}
                        cropShape={'rect'}
                        disableAutomaticStylesInjection={false}
                      />
                  </div>
                            <div className="slider">
                            <input
                              type="range"
                              value={zoom}
                              min={1}
                              max={3}
                              step={0.1}
                              aria-labelledby="Zoom"
                              onChange={(e) => {
                                setZoom(e.target.value);
                              }}
                              className="zoom-range"
                            />
                          </div>
            {/* // <div>
            //   <ReactCrop
            //     style={{ maxWidth: "90%" }}
            //     src={srcImg}
            //     onImageLoaded={setImage}
            //     crop={crop}
            //     onChange={setCrop}
            //   />
            //   <button
            //     className="btn btn-warning cropButton"
            //     onClick={getCroppedImg}
            //   >
            //     Crop
            //   </button>
            // </div> */}
            </> )}
          {/* {result && (
            <div>
              <label>
                <b>{t("cropped_image_header")}</b>
              </label>
              <br />
              <img src={result} alt="cropped image" />
            </div>
          )} */}
        </div>
        </div>
      </Modal>
    </>
  );
}
export default withNamespaces()(SeriesPostEdit);
