import React, { useState, useEffect, useRef,useCallback } from "react";
import { Link, useNavigate } from "react-router-dom";
import { DatePicker, TimePicker,Modal } from "antd";

import { APIEndpoint, postImageUrl } from "../UrlPaths";
import Swal from "sweetalert2";

import { getActiveCategories } from "../services/CategoryAPIServices";
import {uplaodMainImage} from "../services/PostAPIServices";
import {eventActions, getLiveStream} from "../services/EventAPIServices";
import BundledEditor from "../components/BundledEditor";


// import ReactCrop from "react-image-crop";
// import "react-image-crop/dist/ReactCrop.css";
import spacetime from "spacetime";
import TimezoneSelect, { allTimezones } from "react-timezone-select";
import { withNamespaces } from "react-i18next";

import Cropper from 'react-easy-crop'
import getCroppedImg from "../components/cropImage";
import "../components/cropper.css";

function NewEvent({t}) {

  const inputRef = useRef();
  const post_nav = useNavigate();
  const spinner = document.getElementById("spinner");

  const [categoryList, setCategoryList] = useState([]);
  const [title, setTitle] = useState("");
  const [category_id, setCategoryId] = useState("");
  const [description, setPostContent] = useState("");
  const [venue, setVenue] = useState("");
  const [start_date, setStartDate] = useState("");
  const [start_time, setStartTime] = useState("");
  const [end_date, setEndDate] = useState("");
  const [end_time, setEndTime] = useState("");
  const [eventId, setEventId] = useState(0);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [uploadedImage, setUploadedImage] = useState("");
  const [modalTitle, setModalTitle] = useState("Image Crop");
  const [visible, setVisible] = useState(false);
  const [featured_image, setMainImagePath] = useState("");
  const [status, setStatus] = useState("1");
  const [time_zone, setTz] = useState(
    Intl.DateTimeFormat().resolvedOptions().timeZone
  );
  const [datetime, setDatetime] = useState(spacetime.now());
  const [live_streaming_id, setStremID] = useState("");

  const [cropB, setCropB] = useState({ x: 0, y: 0 })
  const [zoom, setZoom] = useState(1)
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [style, setStyle] = useState({  containerStyle: { top: "0",maxWidth:"100%", maxHeight:"100%", overflow: "hidden" },
  mediaStyle: { width: "100%", display: "block" }, 
             });

  var image64Path = "";

  useEffect(() => {
    getActiveCategories().then((response) => {
      setCategoryList(response);
      spinner.style.display = "none";
    });

    const tzValue = time_zone.value ?? time_zone;
    setDatetime(datetime.goto(tzValue));
    console.log(tzValue);

  }, [time_zone]);

  const editorRef = useRef(null);
  const log = () => {
    if (editorRef.current) {
      setPostContent(editorRef.current.getContent());
      console.log(editorRef.current.getContent());
    }
  };

  //-------------------------------------------
  //save the image that used to be crop
  const [image, setImage] = useState(null);
  //change the aspect ratio of crop tool as you preferred
  const [crop, setCrop] = useState({ aspect: 16 / 9 });
  //save the resulted image
  const [result, setResult] = useState(null);
  const [srcImg, setSrcImg] = useState(null);

  const handleImage = async (event) => {
    let img = new Image()
    img.src = window.URL.createObjectURL(event.target.files[0])
    img.onload = () => {
      // console.log(img.width);
        if(img.width >= 800 && img.height >= 450){
          setSrcImg(URL.createObjectURL(event.target.files[0]));
          // console.log(event.target.files[0]);
          // console.log(URL.createObjectURL(event.target.files[0]));
          setVisible(true);
            return true;
        }else{
          Swal.fire("Warning", `Sorry, this image doesn't look like the size we wanted. It's 
          ${img.width} x ${img.height} but we require at least 800 x 450 image.`, "warning");
          return true;
        }
      
    }
  };

  // const getCroppedImg = async () => {
  //   if( crop.width == 0){
  //     Swal.fire("Warning", t("crop_size"), "warning");
  //   }else{
  //   try {
      
  //     const canvas = document.createElement("canvas");
  //     const scaleX = image.naturalWidth / image.width;
  //     const scaleY = image.naturalHeight / image.height;
  //     canvas.width = crop.width;
  //     canvas.height = crop.height;
  //     const ctx = canvas.getContext("2d");
  //     ctx.drawImage(
  //       image,
  //       crop.x * scaleX,
  //       crop.y * scaleY,
  //       crop.width * scaleX,
  //       crop.height * scaleY,
  //       0,
  //       0,
  //       crop.width,
  //       crop.height
  //     );

  //     const base64Image = canvas.toDataURL("image/jpeg", 1);
  //     setResult(base64Image);
  //     console.log(result);
  //   } catch (e) {
  //     console.log("crop the image");
  //   }
  // }
  // };

//----------------- new code ------
const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
  console.log(croppedArea, croppedAreaPixels)
  setCroppedAreaPixels(croppedAreaPixels);
}, []);


 const convertBlobToBase64 = (blob) => new Promise((resolve, reject) => {
  const reader = new FileReader;
  reader.onerror = reject;
  reader.onload = () => {
      resolve(reader.result);
  };
  reader.readAsDataURL(blob);
});

const onUploadCrop = useCallback(async (e) => {
  console.log('croppedAreaPixels'+croppedAreaPixels)
  if (croppedAreaPixels) {
    const [croppedBlob, croppedUri] = await getCroppedImg(
      srcImg,
      croppedAreaPixels
    );
    const base64String = await convertBlobToBase64(croppedBlob);

    setResult(base64String);
    image64Path = base64String;
    setTimeout(handleSubmit(e), 2500);
   
  }
}, [srcImg, croppedAreaPixels]);

//----------------------


  useEffect(() => {
    // console.log(replyDataList);
  }, [srcImg]);

  const onChangeStartDate = (date, dateString) => {
    setStartDate(dateString);
   
  };
  const onChangeEndDate = (date, dateString) => {
    setEndDate(dateString);
  };

  const onChangeStartTime = (time, timeString) => {
    setStartTime(timeString);
  };
  const onChangeEndTime = (time, timeString) => {
    setEndTime(timeString);
  };
  //------------------------------------------

  const handlePostPublish = (e) => {
    e.preventDefault();

      var stID = live_streaming_id;
      if(live_streaming_id == "" || live_streaming_id == null ){
  
        Swal.fire("Warning", t("stream_id_required"), "warning");
       
      }else{
        setButtonLoading(true);
        getLiveStream(stID).then((response) => {
     
          if(response.data){
              const data = {
                live_streaming_id,
                title,
                category_id,
                description,
                featured_image,
                venue,
                start_date,
                start_time,
                end_date,
                end_time,
                time_zone,
                eventId,
                status,
              };
              
              
                eventActions(data).then((response) => {
                  if (response.status_code == 1) {
                    Swal.fire("Success", "" + response.message, "success");
                    setTitle("");
                    setPostContent("");
                    post_nav("/events");
                    post_nav(0);
                  } else {
                    Swal.fire("Warning", " " + response.message, "warning");
                  }
                  setButtonLoading(false);
                });

                 
          }else{
            Swal.fire("Warning", t("no_stream_id"), "warning");
            setStremID("");
            setButtonLoading(false);
           
          }
          
        });
      }

  };


  //---------------------------------------------------------------------

  const example_image_upload_handler = (blobInfo, progress) =>
    new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.withCredentials = false;
      xhr.open("POST", `${APIEndpoint}` + "/uplaodImageFromEditor");

      xhr.upload.onprogress = (e) => {
        progress((e.loaded / e.total) * 100);
      };

      xhr.onload = () => {
        if (xhr.status < 200 || xhr.status >= 300) {
          reject("HTTP Error: " + xhr.status);
          return;
        }

        const json = JSON.parse(xhr.responseText);
        console.log(json);
        // if (!json || typeof json.location != 'string') {
        //   reject('Invalid JSON: ' + xhr.responseText);
        //   return;
        // }

        resolve(`${postImageUrl}` + "/" + json.message);
      };

      xhr.onerror = () => {
        reject(
          "Image upload failed due to a XHR Transport error. Code: " +
            xhr.status
        );
      };

      const formData = new FormData();
      formData.append("file", blobInfo.blob(), blobInfo.filename());

      xhr.send(formData);
    });

  //-----------------------------------------------------------------------------------
  const handleSubmit = (e) => {
    e.preventDefault();
    if (image64Path == "" || image64Path == null) {
      Swal.fire("Warning", t("crop_image"), "warning");
    } else {
    const data = new FormData();
    data.append("file", image64Path);

  
      uplaodMainImage(data).then((response) => {
        if (response.status_code == 1) {
          Swal.fire("Success", t("image_upload_success"), "success");
          setMainImagePath(response.message);
          setUploadedImage(`${postImageUrl}` + "/" + response.message);
          setVisible(false);
        } else {
          Swal.fire("Warning", " " + response.message, "warning");
        }
      });
    }

  };

  //----------------------------------------------------------------
  const getliveStreamData = (action) => {
    var stID = live_streaming_id;
    console.log(stID)
    if(live_streaming_id == "" || live_streaming_id == null ){

      Swal.fire("Warning", t("stream_id_required"), "warning");
      return false;
    }else{
      
      getLiveStream(stID).then((response) => {
        if(response.data){
          if(action == 'load'){
            Swal.fire({title: 'Success',
            text: t("stream_data_load"),
            icon: 'success',
            timer: 2000});
            setTitle(response.data.live_stream.name);
            return true;
          }else{
            return true;
          }
               
        }else{
          Swal.fire("Warning", t("no_stream_id"), "warning");
          setStremID("");
          return false;
        }
        
      });
    }
  }
 //-----------------------------------------------------------------
  const popupDocument = (url) => {
    let win = window.open(
      url,
      null,
      "popup,width=500,height=600,left=0,right=0"
    );
  };

  return (
    <>
      <div className="row">
        <div className="col-8">
          <h3> New Event Creation</h3>
        </div>
      </div>
      <section id="action-form-layout">
        <div className="row match-height">
          <div className="col-md-8">
            <div className="card">
              <div className="card-header">
                <b>
                  <Link to={`/events`}>
                    <i class="ft-chevrons-left"></i>
                    <span for="basic-form-8"> All Events </span>
                  </Link>
                </b>
                {/* <h4 className="card-title">Form Actions</h4> */}
              </div>
              <div className="card-content">
                <div className="card-body">
                <div className="form-row">
                    <div className="col-md-10 col-10">
                      <div className="form-group position-relative">
                        <label for="title">
                          Stream ID (WOWZA) <span style={{ color: "red" }}>*</span>
                        </label>
                        <input
                          type="text"
                          id="stream_id"
                          className="form-control"
                          placeholder="Stream ID (WOWZA)"
                          name="stream_id"
                          value={live_streaming_id}
                          onChange={(e) => setStremID(e.target.value)}
                          required
                        />
                      </div>
                    </div>
                    <div className="col-md-2 col-2">
                    <label for="title" style={{color:'white'}}>
                        ------------
                        </label>
                    <button
                      type="submit"
                      id="publishBtn"
                      className="btn btn-warning"
                      onClick={() => getliveStreamData('load')} 
                    > Load </button>
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="col-md-12 col-12">
                      <div className="form-group position-relative">
                        <label for="title">
                          Title <span style={{ color: "red" }}>*</span>
                        </label>
                        <input
                          type="text"
                          id="title"
                          className="form-control"
                          placeholder="Title"
                          name="title"
                          value={title}
                          onChange={(e) => setTitle(e.target.value)}
                          
                        />
                      </div>
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="col-md-12 col-12">
                      <div className="form-group position-relative">
                        <label for="venue">
                          venue <span style={{ color: "red" }}>*</span>
                        </label>
                        <input
                          type="text"
                          id="venue"
                          className="form-control"
                          placeholder="Venue"
                          name="venue"
                          value={venue}
                          onChange={(e) => setVenue(e.target.value)}
                          required
                        />
                      </div>
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="col-md-6 col-12">
                      <div className="form-group position-relative">
                        <label for="form-action-3">
                          Category <span style={{ color: "red" }}>*</span>
                        </label>
                        <select
                          id="category_id"
                          name="category_id"
                          className="form-control"
                          value={category_id}
                          onChange={(e) => setCategoryId(e.target.value)}
                          required
                        >
                          <option value="" disabled>
                            Select Category
                          </option>
                          {categoryList.map((item, i) => {
                            return (
                              <>
                                {item.status == 1 ? (
                                  <option value={item.id} key={i}>
                                    {item.category_name}
                                  </option>
                                ) : (
                                  <></>
                                )}
                              </>
                            );
                          })}
                        </select>
                      </div>
                    </div>
                    <div className="col-md-6 col-12">
                      <div className="form-group position-relative">
                        <label for="venue">
                          Status <span style={{ color: "red" }}>*</span>
                        </label>
                        <select
                          className="form-control"
                          name="status"
                          id="status"
                          value={status}
                          required
                          onChange={(e) => setStatus(e.target.value)}
                        >
                          <option value="1"> Active</option>
                          <option value="0"> Inactive</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                    <label for="form-action-7">
                      Description <span style={{ color: "red" }}>*</span>
                    </label>

                    <BundledEditor
                      onInit={(evt, editor) => (editorRef.current = editor)}
                      onChange={log}
                      initialValue={description}
                      init={{
                        height: 500,
                        menubar: false,
                        plugins: [
                          "advlist",
                          "anchor",
                          "autolink",
                          "image",
                          "link",
                          "lists",
                          "searchreplace",
                          "table",
                          "wordcount",
                        ],
                        toolbar:
                          "blocks | " +
                          "bold italic forecolor | alignleft aligncenter " +
                          "alignright alignjustify | bullist numlist image | " +
                          " table | outdent indent",
                        toolbar_sticky: true,
                        content_style:
                          "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                        a11y_advanced_options: true,
                        image_advtab: true,
                        image_uploadtab: true,
                        automatic_uploads: true,
                        relative_urls: false,
                        remove_script_host: false,
                        images_upload_handler: example_image_upload_handler,
                      }}
                    />
                  </div>
                  <hr />
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            {/* <div className="card"> */}
            {/* <div className="row"> */}
            <br></br> <br></br> <br></br>
            <div className="row">
              <div className="col-12 col-md-12">
                <div className="form-group">
                  <label for="basic-form-8">
                    starts <span style={{ color: "red" }}>*</span>
                  </label>
                  <br />
                  <DatePicker   format={'DD/MM/YYYY'} onChange={onChangeStartDate} />
                  <TimePicker
                    use24Hours
                    format="HH:mm"
                    onChange={onChangeStartTime}
                  />
                </div>
              </div>
              <div className="col-12 col-md-12">
                <div className="form-group">
                  <label for="basic-form-8">
                    Ends <span style={{ color: "red" }}>*</span>
                  </label>
                  <br />
                  <DatePicker   format={'DD/MM/YYYY'} onChange={onChangeEndDate} />
                  <TimePicker
                    use24Hours
                    format='HH:mm'
                    onChange={onChangeEndTime}
                  />
                </div>
              </div>
              <div className="col-12 col-md-12">
                <div className="form-group">
                  <label for="basic-form-8">
                    TimeZone <span style={{ color: "red" }}>*</span>
                  </label>
                  <br />
                  <TimezoneSelect
                    value={time_zone}
                    onChange={setTz}
                    labelStyle="altName"
                    timezones={{
                      ...allTimezones,
                      "America/Lima": "Pittsburgh",
                      "Europe/Berlin": "Frankfurt",
                    }}
                  />
                </div>
              </div>
              <div className="col-12 col-md-12">
                <div className="form-group">
                  <label for="basic-form-8">
                    Featured Image <span style={{ color: "red" }}>*</span>
                  </label>
                  {/* <ImageResize /> */}
                  {/* <DropZone/> */}
                  <input
                    type="file"
                    name="document"
                    accept="image/*"
                    className="form-control"
                    // ref={inputRef}
                    required
                    onChange={handleImage}
                    // onChange={(e) => setDocument(inputRef.current.files[0])}
                  />
                  <p class="text-muted mb-0 mt-1 mt-sm-0">
                    <small>*Allowed JPG, PNG or JPEG.</small>
                  </p>
                </div>
              </div>
              <div className="col-12 col-md-12">
                {uploadedImage && (
                  <>
                    <img
                      src={uploadedImage}
                      className="img-fluid"
                      alt="cropped image"
                    />

                    <button
                      type="button"
                      id="popupBtnId"
                      class="btn btn-info bg-light-info"
                      onClick={() => popupDocument(`${uploadedImage}`)}
                    >
                      <i className="ft-eye"></i>
                    </button>
                  </>
                )}
              </div>
              <div className="col-12 col-md-12">
                <div className="form-group">
                  <form
                    onSubmit={(e) => {
                      handlePostPublish(e);
                    }}
                  >
                    <button
                      type="submit"
                      id="publishBtn"
                      className="btn btn-primary float-right"
                      disabled={buttonLoading}
                    >
                      {buttonLoading && (
                        <>
                          <i className="ft-refresh-ccw"> </i>
                          <span>Saving...</span>
                        </>
                      )}
                      {!buttonLoading && (
                        <>
                          <i className="ft-check-square mr-1"></i>
                          <span> Save Event</span>{" "}
                        </>
                      )}
                    </button>
                  </form>
                </div>
              </div>
            </div>
            {/* </div> */}
          </div>
        </div>
      </section>
      <Modal
        title={modalTitle}
        visible={visible}
        maskClosable={false}
        okText="Upload"
        onCancel={() => setVisible(false) /*ResetEditing() */}
        onOk={(e) => /*setVisible(false)*/ {
          onUploadCrop(e);
        }}
      >

<div className="container-crop">
      <div className="container-cropper">
          {srcImg && (
          <>
          <div className="cropper" >

          <Cropper
            image={srcImg}
            crop={cropB}
            zoom={zoom}
            aspect={16 / 9}
            onCropChange={setCropB}
            onCropComplete={onCropComplete}
            onZoomChange={setZoom}
            // style={style}
            showGrid={true}
            cropShape={'rect'}
            disableAutomaticStylesInjection={false}
          />
      </div>
          <div className="slider">
            <input
              type="range"
              value={zoom}
              min={1}
              max={3}
              step={0.1}
              aria-labelledby="Zoom"
              onChange={(e) => {
                setZoom(e.target.value);
              }}
              className="zoom-range"
            />
          </div>   
      </>  )}
   
        </div>
        </div>
  
        {/* start previous code */}
        {/* <div>
          {srcImg && (
            <div>
              <ReactCrop
                style={{ maxWidth: "90%" }}
                src={srcImg}
                onImageLoaded={setImage}
                crop={crop}
                onChange={setCrop}
              />
              <button
                className="btn btn-warning cropButton"
                onClick={getCroppedImg}
              >
                Crop
              </button>
            </div>
          )}
          {result && (
            <div>
              <label><b>{t("cropped_image_header")}</b></label><br/>
              <img src={result} alt="cropped image" />
            </div>
          )}
        </div> */}
           {/* end previous code */}
      </Modal>
    </>
  );
}

export default withNamespaces()(NewEvent);

