import React, { useState, useEffect, useRef, useCallback } from "react";
import { getAllEpapers, ePaperActions,uploadThumbnailImage } from "../services/EpaperAPIServices";
import { postImageUrl } from "../UrlPaths";
import Swal from "sweetalert2";
import { Table, Modal, Input, Button, DatePicker } from "antd";
import "antd/dist/antd.css";
import {
  supportedLanguages
} from "../services/PostAPIServices";
import { withNamespaces } from "react-i18next";
import $ from "jquery";
import moment from "moment";
import Cropper from 'react-easy-crop'
import getCroppedImg from "../components/cropImage";
import "../components/cropper.css";

function EPaper({ t }) {
  const inputRef = useRef();
  const inputRef2 = useRef();
  const spinner = document.getElementById("spinner");


  const [tableData, setTableData] = useState([]);
  const [volume_no, setVolumeNo] = useState("");
  const [pdf, setPDF] = useState("");
  const [date, setDate] = useState("");
  const [languagesList, setLanguagesList] = useState([]);
  const [language, setLanguage] = useState("");
  const [epaperId, setEpaperId] = useState(0);
  const [modalTitle, setModalTitle] = useState("Add New E-Paper");
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [searchedText, setSearchedText] = useState("");
  const [videoUploadStatus, setVideoUploadStatus] = useState("");
  const [uploadButton, setUploadButton] = useState(false);
  const [paperPdf, setPaperPdf] = useState("");
  const [result, setResult] = useState(null);
  const [srcImg, setSrcImg] = useState(null);
  const [thumbnailModalTitle, setThumbnailModalTitle] = useState("Crop E-Paper Thumbnail");
  const [thumbnailMVisible, setThumbnailMVisible] = useState(false);
  const [cropB, setCropB] = useState({ x: 0, y: 0 })
  const [zoom, setZoom] = useState(1)
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [thumbnail_img_path, setThumbnailImgPath] = useState("");
  const [uploadedImage, setUploadedImage] = useState("");
  var image64Path = "";
  

  const columns = [
    {
      key: "id",
      title: "ID",
      dataIndex: "id",
      sortDirections: ["descend"],
      hidden: true,
    },

    {
      key: "date",
      title: "Information",
      filteredValue: [searchedText],
      render: (record) => {
        return (
          <>
            <div className="flex">
              <b><h6 style={{color:'blue'}}>Volume : {record.volume_no} - {record.paper_date}</h6></b>
              <p>
                <b>Created At :</b> {record.created_date}
              </p>
            </div>
          </>
        );
      },
      onFilter: (value, record) => {
        return record.paper_date.toLowerCase().includes(value.toLowerCase());
      },
    },
    {
      key: "language",
      title: "Language",
      dataIndex: "language",
    },
    {
      key: "path",
      title: "PDF",
      render: (record) => {
        return (
          <>
            <div className="flex">
              <button
                className="badge bg-light-info"
                style={{ color: "black" }}
                type="submit"
                onClick={() => popupDocument(`${postImageUrl}/${record.pdf}`)}
              >
                <i class="ft-book-open" aria-hidden="true"></i> View
              </button>{" "}
              &emsp;
              
            </div>
          </>
        );
      },
    },
    {
      key: "action",
      title: "Actions",
      render: (record) => {
        return (
          <>
            <div className="flex">
              <button
                className="badge bg-light-danger"
                style={{ color: "black" }}
                onClick={() => Edit(record)}
              >
                <i class="ft-edit"></i>
              </button>
            </div>
          </>
        );
      },
    },
  ].filter((item) => !item.hidden);

  useEffect(() => {
    setLoading(true);

    getAllEpapers().then((response) => {
      spinner.style.display = "none";
      setTableData(response);
      setLoading(false);
    });
    supportedLanguages().then((res) => {
      setLanguagesList(res.languages);
    });
  }, []);

  const onChangeStartDate = (date, dateString) => {
    console.log('ss',dateString);
    setDate(dateString);
   
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(volume_no.match(/^([0-9]+)([-]+)([0-9]+)$/))
    if(volume_no.match(/^([0-9]+)([-]+)([0-9]+)$/) == false || volume_no.match(/^([0-9]+)([-]+)([0-9]+)$/) == null || volume_no == ""){
      Swal.fire("Warning", "Please use correct volume no format.", "warning");
    }else if(thumbnail_img_path == "" || thumbnail_img_path == null){
      if(inputRef2.current != undefined){
        inputRef2.current.value = "";
      }
      Swal.fire("Warning", "Please upload the thumbnail image.", "warning");
    }else{

    const data = new FormData();

    data.append("pdf", pdf);
    data.append("volume_no", volume_no);
    data.append("date", date);
    data.append("language", language);
    data.append("thumbnail_img_path", thumbnail_img_path);
    data.append("epaperId", epaperId);

    setVideoUploadStatus(t("upload_loader"));
    setUploadButton(true)
    ePaperActions(data).then((response) => {
      if (response.status_code == 1) {
        Swal.fire("Success", " " + response.message, "success");
        setVolumeNo("");
        setEpaperId(0);
        setDate("")
        setPDF("");
        setLanguage("");
        setUploadedImage("");
        setThumbnailImgPath("");
        setVideoUploadStatus("");
        inputRef.current.value = "";
        inputRef2.current.value = "";
        setModalTitle("Add New E-Paper");
        setVisible(false);
       
        setLoading(true);
        setUploadButton(false);
        getAllEpapers().then((response) => {
          setTableData(response);
          setLoading(false);
        });
      } else {
        Swal.fire("Warning", " " + response.message, "warning");
        setVideoUploadStatus("");
        setUploadButton(false);
      }
    });
  }
  };

  const handleNewSectorForm = (e) => {
    e.preventDefault();
    setVolumeNo("");
    setPDF("");
    setDate("");
    setUploadedImage("");
    setThumbnailImgPath("");
    setLanguage("");
    setEpaperId(0)
    setVideoUploadStatus("");
    setModalTitle("Add New E-Paper");
    setVisible(true);
    setUploadButton(false);
    console.log(inputRef.current);
    if(inputRef.current != undefined){
      inputRef.current.value = "";
    }
    if(inputRef2.current != undefined){
      inputRef2.current.value = "";
    }
    // onChangeStartDate(date);
    $("#popupBtnId").hide();
    $("#popupBtnId").prop("disabled", true);
    $("#popupBtnId").attr("disabled", "disabled");
    
  };


  const popupDocument = (url) => {
    let win = window.open(
      url,
      null,
      "popup,width=500,height=600,left=0,right=0"
    );
  };

  const Edit = (data) => {
   
    setVolumeNo(data.volume_no);
    // setDate(data.date);
    setLanguage(data.lang);
    setDate(data.date);
    setEpaperId(data.id);
    setModalTitle("Edit E-Paper Details");
    setVisible(true);
    if (data.pdf == null || data.pdf == '') {
      // $("#popupBtnId").css("display", "none");
      $("#popupBtnId").hide();
    } else {
      // $("#popupBtnId").css("display", "block");
      setPaperPdf(postImageUrl + "/" + data.pdf);
      $("#popupBtnId").show();
    }
    setThumbnailImgPath(data.thumbnail_img_path);
    setUploadedImage(`${postImageUrl}` + "/" + data.thumbnail_img_path);
  };

  const handleImage = async (event) => {
    let img = new Image()
    img.src = window.URL.createObjectURL(event.target.files[0])
    img.onload = () => {
      // console.log(img.width);
        if(img.width >= 400 && img.height >= 300){
          setSrcImg(URL.createObjectURL(event.target.files[0]));
          // console.log(event.target.files[0]);
          // console.log(URL.createObjectURL(event.target.files[0]));
          setThumbnailMVisible(true);
            return true;
        }else{
          inputRef2.current.value = "";
          Swal.fire("Warning", `Sorry, this image doesn't look like the size we wanted. It's 
          ${img.width} x ${img.height} but we require at least 400 x 300 image.`, "warning");
          return true;
        }
      
    }
  };

  //----------------- new code ------
const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
  // console.log(croppedArea, croppedAreaPixels)
  setCroppedAreaPixels(croppedAreaPixels);
}, []);

  const convertBlobToBase64 = (blob) => new Promise((resolve, reject) => {
    const reader = new FileReader;
    reader.onerror = reject;
    reader.onload = () => {
        resolve(reader.result);
    };
    reader.readAsDataURL(blob);
  });
  
  const onUploadCrop = useCallback(async (e) => {
    // console.log('croppedAreaPixels'+croppedAreaPixels)
    if (croppedAreaPixels) {
      const [croppedBlob, croppedUri] = await getCroppedImg(
        srcImg,
        croppedAreaPixels
      );
      const base64String = await convertBlobToBase64(croppedBlob);
  
      setResult(base64String);
      image64Path = base64String;
      setTimeout(handleImgSubmit(e), 2500);
     
    }
  }, [srcImg, croppedAreaPixels]);

  const handleImgSubmit = (e) => {
    e.preventDefault();
    if (image64Path == "" || image64Path == null) {
      Swal.fire("Warning", t("crop_image"), "warning");
    } else {
    const data = new FormData();
    data.append("file", image64Path);

  
    uploadThumbnailImage(data).then((response) => {
        if (response.status_code == 1) {
          Swal.fire("Success", t("image_upload_success"), "success");
          setThumbnailImgPath(response.message);
          setUploadedImage(`${postImageUrl}` + "/" + response.message);
          setThumbnailMVisible(false);
        } else {
          Swal.fire("Warning", " " + response.message, "warning");
        }
      });
    }

  };
  return (
    <>
      <div className="row">
        <div className="col-8">
          <h3> E-Paper</h3>
          <p style={{ color: "grey" }}>Upload the e-papers for your site</p>
        </div>
        <div className="col-4">
          <button
            type="button"
            class="btn btn-primary mb-3 d-block  float-right"
            data-toggle="modal"
            data-target="#sectorModal"
            onClick={(e) => handleNewSectorForm(e)}
          >
            Add New
          </button>
        </div>
      </div>

      <section className="users-list-wrapper">
        <div className="users-list-table">
          <div className="row">
            <div className="col-6"></div>
            <div className="col-6">
              <Input.Search
                placeholder="Search here..."
                style={{ margin: 10 }}
                value={searchedText}
                onSearch={(value) => {
                  setSearchedText(value);
                }}
                onChange={(e) => {
                  setSearchedText(e.target.value);
                }}
              />
            </div>
            <div className="col-12">
              <div className="card">
                <div className="card-content">
                  <div className="card-body">
                    <div className="table-responsive">
                      <Table
                        loading={loading}
                        dataSource={tableData}
                        columns={columns}
                        pagination={{
                          pageSize: 10,
                          showSizeChanger: true,
                          position: ["bottomCenter"],
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Modal
        title={modalTitle}
        maskClosable={false}
        visible={visible}
        onCancel={() => setVisible(false) }
        onOk={(e) =>  {
          handleSubmit(e);
        }}
        footer={[
          <Button key="back" onClick={() => setVisible(false)}>
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={loading}
            onClick={handleSubmit}
            disabled={uploadButton}
          >
            <i class="ft-upload m-0 mr-sm-1"></i> Upload
          </Button>,
        ]}
      >
        <form
          onSubmit={(e) => {
            handleSubmit(e);
          }}
        >
          <label className="form-label">
          VOLUME No<span style={{ color: "red" }}>*</span> <span className="text-muted mb-0 mt-1 mt-sm-0">Format: XX-XX</span>
          </label>
          <input
            type="text"
            className="form-control"
            name="title"
            id="title"
            value={volume_no}
            onChange={(e) => setVolumeNo(e.target.value)}
            required
          />
         <div className="row">
          <div className="col-6">
          <label for="basic-form-8">
                    Paper Language <span style={{ color: "red" }}>*</span>
                  </label>
                    <select
                          id="language"
                          name="language"
                          className="form-control"
                          value={language}
                          onChange={(e) => setLanguage(e.target.value)}
                          required
                        >
                          <option value="" disabled>Select Language</option>
                          {languagesList.map((item, i) => {
                            return (
                              <>
                                {item.status == 1 ? (
                                  <option value={item.id} key={i}>
                                    {item.language_in_english}
                                  </option>
                                ) : (
                                  <></>
                                )}
                              </>
                            );
                          })}
                    </select>
          </div>
          <div className="col-6">
          <label className="form-label">
         Paper Date<span style={{ color: "red" }}>*</span>
          </label>
          <br/>
          <DatePicker 
          onChange={onChangeStartDate}
          format={'MM/DD/YYYY'}
          // disabledDate={(current) => current.valueOf() > Date.now()}
          // value={date}
         value={date !== "" ? moment(date) : null}
          />
          <br/>
          </div>
          <div className="col-12 col-md-12">
                <div className="form-group">
                  <label for="basic-form-8">
                  Thumbnail Image <span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    type="file"
                    name="document"
                    accept="image/*"
                    className="form-control"
                    ref={inputRef2}
                    required
                    onChange={handleImage}
                    // onChange={(e) => setDocument(inputRef.current.files[0])}
                  />
                  <p class="text-muted mb-0 mt-1 mt-sm-0">
                    <small>*Allowed JPG, PNG or JPEG.</small>
                  </p>
                </div>
                {uploadedImage != "" ? (<>
                  <a href={uploadedImage} target="_blank"> Uploaded Image</a>
                </>):(<></>)}
              </div>
         </div>
               
          {/* <input
            type="date"
            className="form-control"
            name="title"
            id="title"
            max="2023-02-28"
            placeholder="dd/mm/yyyy"
            value={date}
            onChange={(e) => setDate(e.target.value)}
            required
          /> */}

          <label className="form-label">
            Pdf <span style={{ color: "red" }}>*</span>{" "}
          </label>
          <input
            type="file"
            name="pdf"
            accept="application/pdf"
            className="form-control"
            ref={inputRef}
            required
            onChange={(e) => setPDF(e.target.files[0])}
          />
          <p class="text-muted mb-0 mt-1 mt-sm-0">
            <small>*Allowed .pdf format only</small>
          </p>
          {paperPdf != "" ?(<>          
          <div className="col-md-1" id="popupBtnId">
                          
                          <a

                            class="btn btn-info bg-light-info"
                            onClick={() => popupDocument(`${paperPdf}`)}
                          >
                            <i className="ft-eye"></i>
                          </a>
                        </div></>):(<></>)}
         <b> <p className="text-center" style={{color:'red'}}>{videoUploadStatus}</p></b>
         <input
            type="hidden"
            className="form-control"
            name="epaperId"
            id="epaperId"
            value={epaperId}
            onChange={(e) => setEpaperId(e.target.value)}
            required
          />
        </form>
      </Modal>

      <Modal
        title={thumbnailModalTitle}
        visible={thumbnailMVisible}
        maskClosable={false}
        okText="Upload"
        onCancel={() => setThumbnailMVisible(false) /*ResetEditing() */}
        onOk={(e) =>  {
          onUploadCrop(e);
        }}
      >

<div className="container-crop">
      <div className="container-cropper">
          {srcImg && (
          <>
          <div className="cropper" >

          <Cropper
            image={srcImg}
            crop={cropB}
            zoom={zoom}
            aspect={16 / 9}
            onCropChange={setCropB}
            onCropComplete={onCropComplete}
            onZoomChange={setZoom}
            // style={style}
            showGrid={true}
            cropShape={'rect'}
            disableAutomaticStylesInjection={false}
          />
      </div>
          <div className="slider">
            <input
              type="range"
              value={zoom}
              min={1}
              max={3}
              step={0.1}
              aria-labelledby="Zoom"
              onChange={(e) => {
                setZoom(e.target.value);
              }}
              className="zoom-range"
            />
          </div>   
      </>  )}
   
        </div>
        </div>
      </Modal>
    </>
  );
}
export default withNamespaces()(EPaper);
