import axios from "axios";
import { APIEndpoint } from '../UrlPaths';

const token = localStorage.getItem("win_access_token");

export async function loginAPI(data) {
  try {
    const response = await axios.post(
     APIEndpoint+'/login', data,
      { headers: { "Content-Type": "application/json"} });

    return response.data;
  } catch (error) {
   
    return error;
  }
}

export async function logoutAPI() {
  try {

    const  response  = await axios({
      method: 'post',
      url: APIEndpoint+'/logout',
      headers: {'Authorization': `Bearer ${token}`}
  });

    return response.data;
  } catch (error) {
        return error;
  }
}

export async function getLoggedUserDetails(data) {
  try {
    const response = await axios.post(
      APIEndpoint+'/getLoggedUserDetails',data,
      { headers: { Authorization: `Bearer ${token}` } });

    return response.data;
  } catch (error) {
   
    return error;
  }
}

export async function changePasswordAPI(data) {
  try {
    const response = await axios.post(
      APIEndpoint+'/changePassword',data,
      { headers: { Authorization: `Bearer ${token}` } });

    return response.data;
  } catch (error) {
   
    return error;
  }
}

export async function getAllCustomers() {
  try {
    const response = await axios.get(
      APIEndpoint+'/getAllCustomers',
      { headers: { Authorization: `Bearer ${token}` } });

    return response.data;
  } catch (error) {
   
    return error;
  }
}


export async function updateUserProfile(data) {
  try {
    const response = await axios.post(
      APIEndpoint+'/updateUserProfile',data,
      { headers: { Authorization: `Bearer ${token}` } });

    return response.data;
  } catch (error) {
   
    return error;
  }
}