import React, { useState, useEffect, useRef } from "react";
import { getAllVideos, storeVideo } from "../services/VideoAPIServices";
import { postImageUrl } from "../UrlPaths";
import Swal from "sweetalert2";
import { Table, Modal, Input, Button } from "antd";
import "antd/dist/antd.css";
import ReactPlayer from "react-player";
import { withNamespaces } from "react-i18next";

function VideoUpload({ t }) {
  const inputRef = useRef();

  const spinner = document.getElementById("spinner");
  const [tableData, setTableData] = useState([]);
  const [title, setTitle] = useState("");
  const [video, setVideo] = useState("");
  const [modalTitle, setModalTitle] = useState("Add New Video");
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [searchedText, setSearchedText] = useState("");
  const [videoModalVisible, setVideoModalVisible] = useState(false);
  const [viewVideoUrl, setViewVideoUrl] = useState("");
  const [viewVideoTitle, setViewVideoTitle] = useState("");
  const [videoUploadStatus, setVideoUploadStatus] = useState("");
  const [uploadButton, setUploadButton] = useState(false);

  const columns = [
    {
      key: "id",
      title: "ID",
      dataIndex: "id",
      sortDirections: ["descend"],
      hidden: true,
    },

    {
      key: "title",
      title: "Information",
      filteredValue: [searchedText],
      render: (record) => {
        return (
          <>
            <div className="flex">
              <h5>{record.title}</h5>
              <p>
                <b>Created At :</b> {record.uploaded_date}
              </p>
            </div>
          </>
        );
      },
      onFilter: (value, record) => {
        return record.title.toLowerCase().includes(value.toLowerCase());
      },
    },
    {
      key: "path",
      title: "Video",
      render: (record) => {
        return (
          <>
            <div className="d-flex">
              <button
                className="btn btn-sm bg-light-info"
                style={{ color: "black" }}
                type="submit"
                onClick={() =>
                  viewVideo(`${postImageUrl}` + "/" + record.path, record.title)
                }
              >
                <i class="ft-video" aria-hidden="true"></i> View
              </button>{" "}
              &emsp;
              <button
                className="btn btn-sm bg-light-success"
                style={{ color: "black" }}
                type="submit"
                onClick={() =>
                  copyToClipboard(
                    `${postImageUrl}` + "/" + record.path,
                    record.title
                  )
                }
              >
                <i class="fa fa-copy" aria-hidden="true"></i> Copy URL
              </button>
            </div>
          </>
        );
      },
    },
  ].filter((item) => !item.hidden);

  useEffect(() => {
    setLoading(true);

    getAllVideos().then((response) => {
      // console.log(response.records);
      spinner.style.display = "none";
      setTableData(response.records);
      setLoading(false);
    });
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();

    const data = new FormData();

    data.append("video", video);
    data.append("title", title);

    setVideoUploadStatus(t("upload_loader"));
    setUploadButton(true)
    storeVideo(data).then((response) => {
      if (response.status_code == 1) {
        Swal.fire("Success", " " + response.message, "success");
        setTitle("");
        setVideo("");
        setVideoUploadStatus("");
        inputRef.current.value = "";
        setModalTitle("Add New Video");
        setVisible(false);
       
        setLoading(true);
        setUploadButton(false);
        getAllVideos().then((response) => {
          setTableData(response.records);
          setLoading(false);
        });
      } else {
        Swal.fire("Warning", " " + response.message, "warning");
        setVideoUploadStatus("");
        setUploadButton(false);
      }
    });
  };

  const handleNewSectorForm = (e) => {
    e.preventDefault();
    setTitle("");
    setVideo("");
    setVideoUploadStatus("");
    setModalTitle("Add New Video");
    setVisible(true);
    setUploadButton(false);
  };

  function copyToClipboard(text, title) {
    navigator.clipboard.writeText(text);
    Swal.fire("Success", title + " URL " + t("url_copied"), "success");
  }

  function viewVideo(url, title) {
    setVideoModalVisible(true);
    setViewVideoTitle(title);
    setViewVideoUrl(url);
  }
  return (
    <>
      <div className="row">
        <div className="col-8">
          <h3> Video</h3>
          <p style={{ color: "grey" }}>Upload the videos for your site</p>
        </div>
        <div className="col-4">
          <button
            type="button"
            class="btn btn-primary mb-3 d-block  float-right"
            data-toggle="modal"
            data-target="#sectorModal"
            onClick={(e) => handleNewSectorForm(e)}
          >
            Add New
          </button>
        </div>
      </div>

      <section className="users-list-wrapper">
        <div className="users-list-table">
          <div className="row">
            <div className="col-6"></div>
            <div className="col-6">
              <Input.Search
                placeholder="Search here..."
                style={{ margin: 10 }}
                value={searchedText}
                onSearch={(value) => {
                  setSearchedText(value);
                }}
                onChange={(e) => {
                  setSearchedText(e.target.value);
                }}
              />
            </div>
            <div className="col-12">
              <div className="card">
                <div className="card-content">
                  <div className="card-body">
                    <div className="table-responsive">
                      <Table
                        loading={loading}
                        dataSource={tableData}
                        columns={columns}
                        pagination={{
                          pageSize: 10,
                          showSizeChanger: true,
                          position: ["bottomCenter"],
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Modal
        title={modalTitle}
        maskClosable={false}
        visible={visible}
        onCancel={() => setVisible(false) }
        onOk={(e) =>  {
          handleSubmit(e);
        }}
        footer={[
          <Button key="back" onClick={() => setVisible(false)}>
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={loading}
            onClick={handleSubmit}
            disabled={uploadButton}
          >
            <i class="ft-upload m-0 mr-sm-1"></i> Upload
          </Button>,
        ]}
      >
        <form
          onSubmit={(e) => {
            handleSubmit(e);
          }}
        >
          <label className="form-label">
            Title <span style={{ color: "red" }}>*</span>
          </label>
          <input
            type="text"
            className="form-control"
            name="title"
            id="title"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            required
          />
          <label className="form-label">
            Video <span style={{ color: "red" }}>*</span>{" "}
          </label>
          <input
            type="file"
            name="video"
            accept="video/*"
            className="form-control"
            ref={inputRef}
            required
            onChange={(e) => setVideo(e.target.files[0])}
          />
          <p class="text-muted mb-0 mt-1 mt-sm-0">
            <small>*Allowed .mp4 format</small>
          </p>
         <b> <p className="text-center" style={{color:'red'}}>{videoUploadStatus}</p></b>
        </form>
      </Modal>
      <Modal
        title={viewVideoTitle}
        maskClosable={false}
        visible={videoModalVisible}
        okText="Ok"
        onCancel={() => setVideoModalVisible(false)}
        onOk={(e) => setVideoModalVisible(false)}
      >
        <center>
          <ReactPlayer
            className="react-player"
            url={`${viewVideoUrl}`}
            width="90%"
            controls={true}
          />
        </center>
      </Modal>
    </>
  );
}
export default withNamespaces()(VideoUpload);
