import React, { useState, useEffect } from "react";
import { getAllUsers, userActions, getActiveUserRoles } from "../services/UserAPIServices";
import Swal from "sweetalert2";
import { Table, Modal, Input, Button } from "antd";
import "antd/dist/antd.css";
import {
  supportedLanguages
} from "../services/PostAPIServices";
import { withNamespaces } from "react-i18next";
import { displayUrl } from "../UrlPaths";

function Users({ t }) {
  const spinner = document.getElementById("spinner");

  const [tableData, setTableData] = useState([]);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  let [user_role, setUserRole] = useState("");
  const [languagesList, setLanguagesList] = useState([]);
  const [userRoleList, setUserRoleList] = useState([]);
  const [language, setLanguage] = useState("");
  const [userId, setUserId] = useState(0);
  const [modalTitle, setModalTitle] = useState("Add New Users");
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [searchedText, setSearchedText] = useState("");
  const [saveButtonState, setSaveButton] = useState(false);
  const [status, setStatus] = useState("");
  const [emailReadonly, setEmailReadonly] = useState(false);

  const columns = [
    {
      key: "id",
      title: "ID",
      dataIndex: "id",
      sortDirections: ["descend"],
      hidden: true,
    },

    {
      key: "info",
      title: "Information",
      filteredValue: [searchedText],
      render: (record) => {
        return (
          <>
            <div className="flex">
              <img
                src={`${displayUrl}/${record.profile_img_path}`}
                className="avatar mr-2"
                alt="avatar"
                width="30"
                height="30"
              />
              <span>{record.name}</span><br />
              <span className="text-muted">{record.email}</span>
            </div>
          </>
        );
      },
      onFilter: (value, record) => {
        return record.name.toLowerCase().includes(value.toLowerCase());
      },
    },
    {
      key: "role_name",
      title: "User Role",
      dataIndex: "role_name",
    },
    {
      key: "language",
      title: "Language",
      dataIndex: "lang",
      render: (record) => {
        return (
          <>
            <div className="flex">
              {record == null ? 'All' : record}
            </div>
          </>
        );
      },
    },
    {
      key: "status",
      title: "Status",
      render: (record) => {
        return (
          <>
            {record.status == "1" ? (
              <div className="flex">
                <span className="badge bg-light-success">Active</span>
              </div>
            ) : (
              <div className="flex">
                <span className="badge bg-light-danger">Inactive</span>
              </div>
            )}
          </>
        );
      },
    },
    {
      key: "action",
      title: "Actions",
      render: (record) => {
        return (
          <>
            <div className="flex">
              <button
                className="btn btn-sm bg-light-danger"
                style={{ color: "black" }}
                onClick={() => Edit(record)}
              >
                <i class="ft-edit"></i>
              </button>
            </div>
          </>
        );
      },
    },
  ].filter((item) => !item.hidden);

  useEffect(() => {
    tableDataLoad();
    supportedLanguages().then((res) => {
      setLanguagesList(res.languages);
    });

    getActiveUserRoles().then((response) => {
      setUserRoleList(response);
    });
  }, []);

  function tableDataLoad() {
    setLoading(true);
    getAllUsers().then((response) => {
      spinner.style.display = "none";
      setTableData(response);
      setLoading(false);
    });

  }

  const handleSubmit = (e) => {
    e.preventDefault();

    if(user_role > 1 && language == '0'){
      Swal.fire("Warning", "Only the admin role user can allow all languages. Please select one language.", "warning");
    }else{
      user_role = parseInt(user_role);
      const data = { name, user_role, language, email, status, userId };
      setSaveButton(true)
      userActions(data).then((response) => {
        if (response.status_code == 1) {
          Swal.fire("Success", " " + response.message, "success");
          setVisible(false);
          setSaveButton(false);
          tableDataLoad();
  
        } else {
          Swal.fire("Warning", " " + response.message, "warning");
          setSaveButton(false);
        }
      });
    }
   

  };

  const handleNewSectorForm = () => {
    setEmailReadonly(false);
    setName("");
    setEmail("");
    setUserRole("");
    setLanguage("");
    setUserId(0)
    setStatus("1");
    setModalTitle("Add New User");
    setVisible(true);
    setSaveButton(false);

  };


  const Edit = (data) => {
    setEmailReadonly(true);
    setName(data.name);
    setEmail(data.email);
    setLanguage(data.language);
    setUserRole(data.user_role);
    setUserId(data.id);
    setStatus(data.status);
    setModalTitle("Edit User Details");
    setVisible(true);
  };

  return (
    <>
      <div className="row">
        <div className="col-8">
          <h3> Users</h3>
          <p style={{ color: "grey" }}>System users management</p>
        </div>
        <div className="col-4">
          <button
            type="button"
            class="btn btn-primary mb-3 d-block  float-right"
            onClick={(e) => handleNewSectorForm(e)}
          >
            Add New
          </button>
        </div>
      </div>

      <section className="users-list-wrapper">
        <div className="users-list-table">
          <div className="row">
            <div className="col-6"></div>
            <div className="col-6">
              <Input.Search
                placeholder="Search here..."
                style={{ margin: 10 }}
                value={searchedText}
                onSearch={(value) => {
                  setSearchedText(value);
                }}
                onChange={(e) => {
                  setSearchedText(e.target.value);
                }}
              />
            </div>
            <div className="col-12">
              <div className="card">
                <div className="card-content">
                  <div className="card-body">
                    <div className="table-responsive">
                      <Table
                        loading={loading}
                        dataSource={tableData}
                        columns={columns}
                        pagination={{
                          pageSize: 10,
                          showSizeChanger: true,
                          position: ["bottomCenter"],
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Modal
        title={modalTitle}
        maskClosable={false}
        visible={visible}
        onCancel={() => setVisible(false)}
        onOk={(e) => {
          handleSubmit(e);
        }}
        footer={[
          <Button key="back" onClick={() => setVisible(false)}>
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={saveButtonState}
            onClick={handleSubmit}
          >
            Save
          </Button>,
        ]}
      >
        <form>
          <label className="form-label">
            Name with initials (website display name) <span style={{ color: "red" }}>*</span>
          </label>
          <input
            type="text"
            className="form-control"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />
          <label className="form-label">
            Login Email <span style={{ color: "red" }}>*</span>
          </label>
          <input
            type="text"
            className="form-control"
            value={email}
            readOnly={emailReadonly}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          <div className="row">
            <div className="col-6">
              <label className="form-label">
                User role <span style={{ color: "red" }}>*</span>
              </label>
              <select
                className="form-control"
                value={user_role}
                onChange={(e) => setUserRole(e.target.value)}
                required
              >
                <option value="" selected disabled>Select user role</option>
                {userRoleList.map((item, i) => {
                  return (
                    <>
                      <option value={item.id} key={i}>
                        {item.role_name}
                      </option>
                    </>
                  );
                })}
              </select>
            </div>
            <div className="col-6">
              <label for="basic-form-8">
                Editor's language <span style={{ color: "red" }}>*</span>
              </label>
              <select
                className="form-control"
                value={language}
                onChange={(e) => setLanguage(e.target.value)}
                required
              >
                <option value="" selected disabled>Select Language</option>
                <option value="0" >All</option>
                {languagesList.map((item, i) => {
                  return (
                    <>
                      {item.status == 1 ? (
                        <option value={item.id} key={i}>
                          {item.language_in_english}
                        </option>
                      ) : (
                        <></>
                      )}
                    </>
                  );
                })}
              </select>
            </div>
            <div className="col-6">
              <label className="form-label">
                Status <span style={{ color: "red" }}>*</span>
              </label>
              <select
                className="form-control"
                value={status}
                required
                onChange={(e) => setStatus(e.target.value)}
              >
                <option value="1"> Active</option>
                <option value="0"> Inactive</option>
              </select>
            </div>
          </div>
        </form>
      </Modal>

    </>
  );
}
export default withNamespaces()(Users);
