import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import './i18n';
import reportWebVitals from "./reportWebVitals";


const root = ReactDOM.createRoot(document.getElementById("root"));

// function AppWithProvider() {
//     return (
//          <App />    
//     );
//   }

  root.render(<App />);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
