import { Routes, Route } from "react-router-dom";
import Login from "./pages/Login";
import Dashboard from "./pages/Dashboard";
import ForgotPassword from "./pages/ForgotPassword";
import ResetPassword from "./pages/ResetPassword";
import UserProfile from "./pages/UserProfile";
import Category from "./pages/Category";
import Post from "./pages/Post";
import NewPost from "./pages/NewPost";
import PostEdit from "./pages/PostEdit";
import PostRevisions from "./pages/PostRevisions";
import PostView from "./pages/PostView";
import Event from "./pages/Event";
import NewEvent from "./pages/NewEvent";
import EventEdit from "./pages/EventEdit";
import VideoUpload from "./pages/VideoUpload";
import Viewers from "./pages/Viewers";
import EPaper from "./pages/EPaper";
import WeekPicture from "./pages/WeekPicture";
import WeekVideo from "./pages/WeekVideo";
import Advertisement from "./pages/Advertisement";
import Notification from "./pages/Notification";
import Matches from "./pages/Matches";
import NewSeriesPost from "./pages/NewSeriesPost";
import SeriesPost from "./pages/SeriesPost";
import SeriesPostEdit from "./pages/SeriesPostEdit";
import SeriesPostView from "./pages/SeriesPostView";
import Series from "./pages/Series";
import Users from "./pages/Users";
import Comment from "./pages/Comment";

export const AuthenticatedRoutes = () => {
  return (
    <>
      <Routes>
      <Route path="/dashboard" element={<Dashboard />} />
      <Route path="/account" element={<UserProfile />} /> 
      <Route path="/categories" element={<Category />} />
      <Route path="/posts" element={<Post />} />
      <Route path="/new-post" element={<NewPost />} />
      <Route path="/post-edit/:id" element={<PostEdit />} /> 
      <Route path="/post-revisions/:id" element={<PostRevisions />} />  
      <Route path="/post-view/:id" element={<PostView />} />   
      <Route path="/events" element={<Event />} /> 
      <Route path="/new-event" element={<NewEvent />} /> 
      <Route path="/event-edit/:id" element={<EventEdit />} />
      <Route path="/video-upload" element={<VideoUpload />} /> 
      <Route path="/viewers" element={<Viewers />} /> 
      <Route path="/e-paper" element={<EPaper />} /> 
      <Route path="/week-picture" element={<WeekPicture />} /> 
      <Route path="/week-video" element={<WeekVideo />} />
      <Route path="/advertisements" element={<Advertisement />} />
          <Route path="/notifications" element={<Notification />} />
          <Route path="/matches" element={<Matches />} />
          <Route path="/new-series-post" element={<NewSeriesPost />} />
          <Route path="/series-news" element={<SeriesPost />} />
          <Route path="/series-news-edit/:id" element={<SeriesPostEdit />} />
          <Route path="/series-news/:id" element={<SeriesPostView />} />
          <Route path="/series" element={<Series />} />
          <Route path="/users" element={<Users />} />
          <Route path="/comments" element={<Comment />} />
      </Routes>
    </>
  );
};

export const UnauthenticatedRoutes = () => {
  return (
    <Routes>
       <Route path="*" element={<Login />} />
       <Route path="/forgot-password" element={<ForgotPassword />} />
       <Route path="/password/reset/:token/:mail" element={<ResetPassword />} />
    </Routes>
  );
};

