import axios from "axios";
import { APIEndpoint } from "../UrlPaths";
const token = localStorage.getItem("win_access_token");


export async function getAdvertisements(data) {
  try {
    const response = await axios.post(APIEndpoint + "/getAdvertisements", data, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data.data;
  } catch (error) {
    return error;
  }
}

export async function advertisementImgStore(data) {
  try {
    const response = await axios.post(APIEndpoint + "/advertisementImgStore", data, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data;
  } catch (error) {
    return error;
  }
}
export async function storeWeekResource(data) {
  try {
    const response = await axios.post(APIEndpoint + "/storeWeekResource", data, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data;
  } catch (error) {
    return error;
  }
}

export async function deleteAdvertisement(data) {
  try {
    const response = await axios.post(APIEndpoint + "/deleteAdvertisement", data, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data;
  } catch (error) {
    return error;
  }
}
