import axios from "axios";
import { APIEndpoint} from "../UrlPaths";

const token = localStorage.getItem("win_access_token");

export async function getSeriesList() {
  try {
    const response = await axios.get(APIEndpoint + "/getSeriesList", {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data.data;
  } catch (error) {
    return [];
  }
}

export async function getAllMatchSeriesNews() {
  try {
    const response = await axios.get(APIEndpoint + "/getAllSeriesPosts", {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data.data;
  } catch (error) {
    return [];
  }
}

export async function seriesPostActions(data) {
  try {
    const response = await axios.post(APIEndpoint + "/storeSeriesPost", data, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data;
  } catch (error) {
    return error;
  }
}

export async function getMatchSeriesPostContent(data) {
  try {
    const response = await axios.post(
      `${APIEndpoint}/getSingleSeriesPostData`, data,
      { headers: { 'Authorization': `Bearer ${token}`} });

    return response.data;
  } catch (error) {
   
    return error;
  }
}

export async function uploadSeriesMainImage(data) {
  try {
    const response = await axios.post(
        `${APIEndpoint}/uploadSeriesMainImage`, data,
        { headers: { 'Authorization': `Bearer ${token}` , 'Content-Type': 'multipart/form-data'} });

    return response.data;
  } catch (error) {

    return error;
  }
}

export async function seriesPostStatusChange(data) {
  try {
    const response = await axios.post(
        `${APIEndpoint}/seriesPostStatusChange`, data,
        { headers: { 'Authorization': `Bearer ${token}`} });

    return response.data;
  } catch (error) {

    return error;
  }
}


export async function getParentSeriesList() {
  try {
    const response = await axios.get(APIEndpoint + "/getParentSeriesList", {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data.data;
  } catch (error) {
    return [];
  }
}

export async function getAllSeries() {
  try {
    const response = await axios.get(APIEndpoint + "/getAllSeries", {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data.data;
  } catch (error) {
    return [];
  }
}

export async function storeSeriesActions(data) {
  try {
    const response = await axios.post(APIEndpoint + "/storeSeries", data, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data;
  } catch (error) {
    return error;
  }
}

export async function pointTableContent(data) {
  try {
    const response = await axios.post(APIEndpoint + "/pointTableContent", data, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data;
  } catch (error) {
    return error;
  }
}
export async function updatePointsTableData(data) {
  try {
    const response = await axios.post(APIEndpoint + "/updatePointsTableData", data, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data;
  } catch (error) {
    return error;
  }
}

export async function deleteSeriesData(data) {
  try {
    const response = await axios.post(APIEndpoint + "/deleteSeries", data, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data;
  } catch (error) {
    return error;
  }
}

export async function getAllThridPartySeries() {
  try {
    const response = await axios.get(APIEndpoint + "/getThirdPartySeriseList", {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data;
  } catch (error) {
    return [];
  }
}

export async function storeThirdPartyPointsData(data) {
  try {
    const response = await axios.post(APIEndpoint + "/storeSeriesPointsTableData",data, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data;
  } catch (error) {
    return [];
  }
}