import axios from "axios";
import { APIEndpoint } from '../UrlPaths';
import $ from "jquery";
const token = localStorage.getItem("win_access_token");


export async function getRightsAccess(base_data) {
  try {
    const response = await axios.post(
      APIEndpoint+'/getGrantedPermissions',base_data,
      { headers: { Authorization: `Bearer ${token}` } }
    );
    let access_checking = false;
    let checking_count = 0;
    
  

    $.each(response.data.data, function (k, v) {
      const href_path = v.href;
  
  
      if (href_path == base_data.current_path) {
        checking_count++;
      } else if (
        href_path == "posts" &&
        base_data.current_path == "post-edit"
      ) {
        checking_count++;
      } else if (
        href_path == "posts" &&
        base_data.current_path == "post-view"
      ) {
        checking_count++;
      } else if (base_data.current_path == "account") {
        checking_count++;
      }else if (
        href_path == "posts" &&
        base_data.current_path == "new-post"
      ) {
        checking_count++;
      }
    });

    if (checking_count == 0) {
      access_checking = false;

    } else {
      access_checking = true;
    }

    return access_checking;
  } catch (error) {
    console.log(error);
    return error;
  }
}