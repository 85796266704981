import { useParams, Link } from "react-router-dom";
import React, { useState, useEffect } from "react";

import {getMatchSeriesPostContent} from "../services/MatchSeriesAPIServices";

function SeriesPostView() {
  const { id } = useParams();
  const spinner = document.getElementById("spinner");
  const [title, setTitle] = useState("");
  const [post_content, setPostContent] = useState("");

  useEffect(() => {
    var data = { id };
    getMatchSeriesPostContent(data).then((response) => {
      setTitle(response.data.title);
      setPostContent(response.data.post_content);
      spinner.style.display = "none";
    });
  }, []);

  function createMarkup() {
    return { __html: post_content };
  }

  return (
    <>
      <div className="row">
        <div className="col-12">
          <h3> Post - {title} </h3>
        </div>
      </div>
      <section id="action-form-layout">
        <div className="row match-height">
          <div className="col-12">
            <div className="card">
              <div className="card-header"></div>
              <div className="card-content">
                <div className="card-body">
                  <div className="row">
                    <div className="col-12 col-md-6">
                      <div className="form-group float-left">
                        <b>
                          <Link to={`/series-news`}>
                            {" "}
                            <i class="ft-chevrons-left"></i>
                            <span for="basic-form-8"> All Series News Post </span>
                          </Link>
                        </b>
                      </div>
                    </div>
                    <div className="col-12 col-md-6">
                      <div className="form-group float-right">
                        <b>
                          <Link to={`/series-news-edit/${id}`}>
                            <span for="basic-form-8">Edit </span>
                            <i class="ft-edit"></i>
                          </Link>
                        </b>
                      </div>
                    </div>
                    <div className="ck-content" dangerouslySetInnerHTML={createMarkup()} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
export default SeriesPostView;
