import axios from "axios";
import { APIEndpoint } from '../UrlPaths';

const token = localStorage.getItem("win_access_token");


export async function getDashboardData() {
  try {
    const response = await axios.get(
      APIEndpoint+'/getDashboardData',
      { headers: { Authorization: `Bearer ${token}` } }
    );
    return response.data.data;
  } catch (error) {
    console.log(error);
    return error;
  }
}