import React, { useState, useEffect, useRef } from "react";
import {
  storeWeekResource,
  getAllResource,
  deleteResource,
} from "../services/WeekResourcesAPIServices";

import Swal from "sweetalert2";
import {Table, Modal, Button, DatePicker, TimePicker} from "antd";
import "antd/dist/antd.css";
import { withNamespaces } from "react-i18next";
import ReactPlayer from "react-player";
import {getAllNotification, storeNotification} from "../services/NotificationAPIServices";
import moment from "moment";

function Notification({ t }) {
  const inputRef = useRef();
  const spinner = document.getElementById("spinner");
  const [tableData, setTableData] = useState([]);
  const [resource_type, setResourceType] = useState("video");
  const [resource_input, setResourceInput] = useState("video_link");
  const [modalTitle, setModalTitle] = useState("Add New Video");
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [title, setTitle] = useState("");
  const [message, setMessage] = useState("");
  const [uploadButton, setUploadButton] = useState(false);
  const [date, setSendingDate] = useState("");
  const [time, setSendingTime] = useState("");

  const columns = [
    {
      key: "id",
      title: "ID",
      dataIndex: "id",
      sortDirections: ["descend"],
      hidden: true,
    },
    {
      key: "title",
      title: "Title",
      dataIndex: "title",
    },
    {
      key: "message",
      title: "Message",
      dataIndex: "message",
    },
    {
      key: "date",
      title: "Sending Date",
      dataIndex: "date",
      render: (record) => {
        return (
            <>
              {moment(record).format('DD/MM/YYYY H:mm:ss')}
            </>
        );
      },
    },
    {
      key: "status",
      title: "Sending Status",
      dataIndex: "status",
    },
    {
      key: "created_at",
      title: "Created Date",
      dataIndex: "created_at",
      render: (record) => {
        return (
            <>
              {moment(record).format('DD/MM/YYYY H:mm:ss')}
            </>
        );
      },
    },
  ].filter((item) => !item.hidden);

  useEffect(() => {
    setLoading(true);

    getAllNotification().then((response) => {
      spinner.style.display = "none";
      setTableData(response);
      setLoading(false);
    });
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();

    const data ={title, message, date, time};

    storeNotification(data).then((response) => {
      if (response.status_code == 1) {
        Swal.fire("Success", "" + response.message, "success").then(function () {
          setVisible(false);
          setLoading(true);
          getAllNotification().then((response) => {
            setTableData(response);
            setLoading(false);
          });
        });
      } else {
        Swal.fire("Warning", " " + response.message, "warning");
      }
    });
  };

  const onChangeStartTime = (time, timeString) => {
    setSendingTime(timeString);
  };

  const onChangeStartDate = (date, dateString) => {
    setSendingDate(dateString);

  };

  const handleNewSectorForm = (e) => {
    e.preventDefault();
    setModalTitle("Send Notification");
    setTitle("");
    setMessage("");
    setSendingDate("");
    setSendingTime("");
    setVisible(true);
  };

  return (
    <>
      <div className="row">
        <div className="col-8">
          <h3>Notifications</h3>
          <p style={{ color: "grey" }}>Manage the notifications</p>
        </div>
        <div className="col-4">
          <button
              type="button"
              className="btn btn-primary mb-3 d-block  float-right"
              data-toggle="modal"
              data-target="#sectorModal"
              onClick={(e) => handleNewSectorForm(e)}
          >
            Send Notification
          </button>
        </div>
      </div>

      <section className="users-list-wrapper">
        <div className="users-list-table">
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-content">
                  <div className="card-body">
                    <div className="table-responsive">
                      <Table
                        loading={loading}
                        dataSource={tableData}
                        columns={columns}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Modal
        title={modalTitle}
        maskClosable={false}
        visible={visible}
        onCancel={() => setVisible(false)}
        onOk={(e) => {
          handleSubmit(e);
        }}
        footer={[
          <Button key="back" onClick={() => setVisible(false)}>
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={loading}
            onClick={handleSubmit}
            disabled={uploadButton}
          >
            Save
          </Button>,
        ]}
      >
        <form
          onSubmit={(e) => {
            handleSubmit(e);
          }}
        >
          <div className="row">
          <div className="col-md-12">
            <label className="form-label">
                Title <span style={{ color: "red" }}>*</span>
            </label>
            <input
                type="text"
                className="form-control"
                name="title"
                id="title"
                value={title}
                placeholder={"Title"}
                onChange={(e) => setTitle(e.target.value)}
                required
            />
          </div>
            <div className="col-md-12">
              <label className="form-label">
                Message <span style={{ color: "red" }}>*</span>
              </label>
              <textarea className="form-control" id="message" name="message" value={message} onChange={(e) => setMessage(e.target.value)} placeholder="Enter Message Here" />
            </div>
            <div className="col-md-12">
              <label className="form-label">Sending Date</label><br/>
              <DatePicker
                  disabledDate={(current) => {
                    return moment().add(-1, 'days')  >= current
                  }}
                  format={'DD/MM/YYYY'} onChange={onChangeStartDate}  />
              <TimePicker
                  use24Hours
                  format="HH:mm"
                  onChange={onChangeStartTime}
              />
            </div>
          </div>
        </form>
      </Modal>
    </>
  );
}
export default withNamespaces()(Notification);
