import React, { useState, useEffect, useRef } from "react";
import {
  storeWeekResource,
  getAllResource,
  deleteResource,
} from "../services/WeekResourcesAPIServices";

import Swal from "sweetalert2";
import { Table, Modal, Button } from "antd";
import "antd/dist/antd.css";
import { withNamespaces } from "react-i18next";
import ReactPlayer from "react-player";

function WeekVideo({ t }) {
  const inputRef = useRef();
  const spinner = document.getElementById("spinner");
  const [tableData, setTableData] = useState([]);
  const [resource_type, setResourceType] = useState("video");
  const [resource_input, setResourceInput] = useState("video_link");
  const [resource_id, setResourceId] = useState("0");
  const [modalTitle, setModalTitle] = useState("Add New Video");
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);

  const [path, setResourcePath] = useState("");
  const [videoUploadStatus, setVideoUploadStatus] = useState("");
  const [uploadButton, setUploadButton] = useState(false);
  const [uploadSectionVisible, setuploadSectionVisible] = useState(false);
  const [videoSectionVisible, setUtubeSectionVisible] = useState(true);

  const columns = [
    {
      key: "id",
      title: "ID",
      dataIndex: "id",
      sortDirections: ["descend"],
      hidden: true,
    },
    {
      key: "path",
      title: " ",
      render: (record) => {
        return (
          <>
            {record.path == "" ? (
              <>
                <label>No video uploaded</label>
              </>
            ) : (
              <>
                <ReactPlayer
                  className="react-player"
                  url={`${record.path}`}
                  width="100%"
                  controls
                  config={{
                    file: {
                      attributes: {
                        controlsList: "nodownload",
                      },
                    },
                  }}
                />
              </>
            )}
          </>
        );
      },
    },
    {
      key: "uploaded_date",
      title: "Last Modified",
      dataIndex: "uploaded_date",
    },
    {
      key: "action",
      title: "Actions",
      render: (record) => {
        return (
          <>
            <div className="d-flex">
              <button
                className="btn bg-light-info"
                style={{ color: "black" }}
                onClick={() => Edit(record)}
              >
                Upload / <i class="ft-edit"></i>
              </button>
              &nbsp;
              <button
                type="submit"
                className="btn bg-light-danger"
                onClick={() => deleteImage(record)}
              >
                <span for="basic-form-8"> </span>
                <i class="fa fa-trash"></i>
              </button>
            </div>
          </>
        );
      },
    },
  ].filter((item) => !item.hidden);

  useEffect(() => {
    setLoading(true);

    const data = {
      resource_type,
    };
    getAllResource(data).then((response) => {
      spinner.style.display = "none";
      setTableData(response.records);
      setLoading(false);
    });
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();

    const data = new FormData();

    data.append("path", path);
    data.append("resource_type", resource_type);
    data.append("resource_input", resource_input);
    data.append("resource_id", resource_id);

    setVideoUploadStatus(t("upload_loader"));
    setUploadButton(true);
    storeWeekResource(data).then((response) => {
      if (response.status_code == 1) {
        Swal.fire("Success", " " + response.message, "success");

        setVideoUploadStatus("");

        if (resource_input == "video") {
          inputRef.current.value = "";
        }
        setResourceInput("video_link");
        setModalTitle("Add New Video");
        setVisible(false);
        setResourcePath("");
        setuploadSectionVisible(false);
        setUtubeSectionVisible(true);
        setLoading(true);
        setUploadButton(false);
        const dataload = {
          resource_type,
        };
        setLoading(true);
        getAllResource(dataload).then((response) => {
          setTableData(response.records);
          setLoading(false);
        });
      } else {
        Swal.fire("Warning", " " + response.message, "warning");
        setVideoUploadStatus("");
        setUploadButton(false);
      }
    });
  };

  // const handleSubmit = (e) => {
  //   e.preventDefault();

  //   const data = {
  //     resource_type,
  //     path,
  //     resource_id,
  //   };

  //   storeWeekResource(data).then((response) => {
  //     if (response.status_code == 1) {
  //       Swal.fire("Success", " " + response.message, "success");
  //       setResourceId(0);
  //       setResourceType("video");
  //       setResourcePath("");
  //       setModalTitle("Add New Video");
  //       setVisible(false);
  //       document.querySelector('#image').value = "";
  //       const dataload = {
  //         resource_type,
  //       };
  //       setLoading(true);
  //       getAllResource(dataload).then((response) => {
  //         setTableData(response.records);
  //         setLoading(false);
  //       });
  //     } else {
  //       Swal.fire("Warning", " " + response.message, "warning");
  //     }
  //   });
  // };

  const Edit = (data) => {
    setResourceId(data.id);
    if (data.path == "" || data.path == null) {
      setModalTitle("Upload Video");
    } else {
      setModalTitle("Edit Video");
    }

    // setResourcePath(data.path);
    setVisible(true);
  };

  const deleteImage = (data) => {
    const dataDelete = new FormData();
    dataDelete.append("resource_id", data.id);
    deleteResource(dataDelete).then((response) => {
      if (response.status_code == 1) {
        Swal.fire("Success", " " + response.message, "success");
        const dataload = {
          resource_type,
        };
        setLoading(true);
        getAllResource(dataload).then((response) => {
          setTableData(response.records);
          setLoading(false);
        });
      } else {
        Swal.fire("Warning", " " + response.message, "warning");
      }
    });
  };
  const selectionType = async (e) => {
    if (resource_input == "video_link") {
      setUtubeSectionVisible(false);
      setuploadSectionVisible(true);
    } else {
      setUtubeSectionVisible(true);
      setuploadSectionVisible(false);
    }
  };

  return (
    <>
      <div className="row">
        <div className="col-12">
          <h3>Video of Week</h3>
          <p style={{ color: "grey" }}>Manage the week video on your site</p>
        </div>
      </div>

      <section className="users-list-wrapper">
        <div className="users-list-table">
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-content">
                  <div className="card-body">
                    <div className="table-responsive">
                      <Table
                        loading={loading}
                        dataSource={tableData}
                        columns={columns}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Modal
        title={modalTitle}
        maskClosable={false}
        visible={visible}
        onCancel={() => setVisible(false)}
        onOk={(e) => {
          handleSubmit(e);
        }}
        footer={[
          <Button key="back" onClick={() => setVisible(false)}>
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={loading}
            onClick={handleSubmit}
            disabled={uploadButton}
          >
            Save
          </Button>,
        ]}
      >
        <form
          onSubmit={(e) => {
            handleSubmit(e);
          }}
        >
          <div className="col-12">
            <label className="form-label">
              Resource Type <span style={{ color: "red" }}>*</span>
            </label>
            <select
              className="form-control"
              name="resource_type"
              id="resource_type"
              value={resource_input}
              required
              onChange={(e) => {
                setResourceInput(e.target.value);
                selectionType();
              }}
            >
              <option value="video_link"> You Tube Video Link</option>
              <option value="video"> Video Upload</option>
            </select>
          </div>
          {uploadSectionVisible == true ? (
            <>
              <div className="col-12">
                <label className="form-label">
                  Video <span style={{ color: "red" }}>*</span>{" "}
                </label>
                <input
                  type="file"
                  name="video"
                  accept="video/*"
                  className="form-control"
                  ref={inputRef}
                  required
                  onChange={(e) => setResourcePath(e.target.files[0])}
                />
                <p class="text-muted mb-0 mt-1 mt-sm-0">
                  <small>*Allowed .mp4 format</small>
                </p>
              </div>
              <b>
                {" "}
                <p className="text-center" style={{ color: "red" }}>
                  {videoUploadStatus}
                </p>
              </b>
            </>
          ) : (
            <>
              {" "}
              <div className="col-12">
                <label className="form-label">
                  Insert Video Link Path <span style={{ color: "red" }}>*</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="videoPath"
                  id="videoPath"
                  value={path}
                  onChange={(e) => setResourcePath(e.target.value)}
                />
              </div>
            </>
          )}

          <input
            type="hidden"
            className="form-control"
            name="resource_id"
            id="resource_id"
            value={resource_id}
            onChange={(e) => setResourceId(e.target.value)}
            required
          />
        </form>
      </Modal>
    </>
  );
}
export default withNamespaces()(WeekVideo);
