import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { getAllEvents,createEventStream ,storeStreamProjectData, startLiveStream, storeStreamState, endLiveStream, getLiveStreamState} from "../services/EventAPIServices";
import { Table, Input, Button } from "antd";
import "antd/dist/antd.css";
import { SearchOutlined } from "@ant-design/icons";
import Swal from "sweetalert2";
import { withNamespaces } from "react-i18next";

function Event({ t }) {
  const spinner = document.getElementById("spinner");
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(false);
  let event_list = [];

  const columns = [
    {
      key: "id",
      title: "ID",
      dataIndex: "id",
      sortDirections: ["descend"],
      hidden: true,
    },
    {
      key: "title",
      title: "Title",
      dataIndex: "title",
      filterMode: "tree",
      filterSearch: true,
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              autoFocus
              placeholder="Type title"
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropDown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            ></Input>
            <Button
              onClick={() => {
                confirm();
              }}
              type="primary"
            >
              Search
            </Button>
          </>
        );
      },
      filterIcon: () => {
        return <SearchOutlined />;
      },
      onFilter: (value, record) => {
        return record.title.toLowerCase().includes(value.toLowerCase());
      },
    },
    {
      key: "venue",
      title: "Venue",
      dataIndex: "venue",
    },
    {
      key: "start_date",
      title: "Start Date",
      dataIndex: "start_date",
    },
    {
      key: "start_time",
      title: "Start Time",
      dataIndex: "start_time",
    },
    {
      key: "live_streaming_state",
      title: "Streaming State",
      render: (record) => {
        return (
          <>
            {" "}
            <div className="flex">
            
                <span className="badge bg-light-info">{record.live_streaming_state}</span>
           
            </div>
          </>
        );
      },
    },
    {
      key: "live_streaming_state",
      title: "Console Link",
      render: (record) => {
        return (
          <>
            {" "}
            <div className="flex">
            
            {record.live_streaming_id != "" || record.live_streaming_id != null ? (
                 <button
                 className="btn btn-danger"
                 style={{ color: "black" }}
                 onClick={() => goToStreamPlatform(record)}
               >
                Go To Console  <i class="ft-radio"> </i>
               </button>
              ) : (<></>)}
            </div>
          </>
        );
      },
    },
    // {
    //   key: "live_streaming_id",
    //   title: "Streaming",
    //   render: (record) => {
    //     return (
    //       <>
    //         {" "}
    //         <div className="flex">
    //           {record.live_streaming_id == "" || record.live_streaming_id == null ? (
    //              <button
    //              className="btn btn-info"
    //              style={{ color: "black" }}
    //              onClick={() => createStream(record)}
    //            >
    //              Create Stream
    //            </button>
    //           ) : (
    //             record.live_streaming_state == "created" ? (<> <button
    //               className="btn btn-warning"
    //               style={{ color: "black" }}
    //               onClick={() => startLiveStreamW(record)}  
    //             >
    //               <i class="ft-radio"> Start </i>
    //             </button>
    //                           <button
    //                           className="badge bg-light-success"
    //                           style={{ color: "black" }}
    //                           type="submit"
    //                           onClick={() =>
    //                             copyToClipboard(
    //                              record.source_primary_server,
    //                               'Test'
    //                             )
    //                           }
    //                         >
    //                           <i class="fa fa-copy" aria-hidden="true"></i> Source Server Link
    //                         </button>
    //                         </> ):(
    //               record.live_streaming_state == "starting" ? (
    //               <>{record.live_streaming_state}...</>
    //               ):(
    //                 record.live_streaming_state == "started" ?(<>
    //                 <button
    //               className="btn btn-danger"
    //               style={{ color: "black" }}
    //               onClick={() => stopLiveStream(record)}  
    //             >
    //               <i class="ft-radio"> Stop </i>
    //             </button>
    //                 </>):(<>{record.live_streaming_state}</>)
    //              )
    //             )
               
    //           )}
    //         </div>
    //       </>
    //     );
    //   }
    // },
    {
      key: "status",
      title: "Status",
      render: (record) => {
        return (
          <>
            {" "}
            <div className="flex">
              {record.status == "1" ? (
                <span className="badge bg-light-success">Active</span>
              ) : (
                <span className="badge bg-light-danger">Inactive</span>
              )}
            </div>
          </>
        );
      },
    },
    {
      key: "action",
      title: "Actions",
      render: (record) => {
        return (
          <>
            <div className="flex">
            <Link to={`/event-edit/${record.id}`}>
                <button className="btn btn-sm bg-light-danger">
                  <i class="ft-edit"></i>
                </button>
              </Link>
            </div>
          </>
        );
      },
    },
  ].filter((item) => !item.hidden);

  useEffect(() => {
    setLoading(true);

    getAllEvents().then((response) => {
      //  console.log(response);
      spinner.style.display = "none";
      setTableData(response);
      event_list = response;
      setLoading(false);
  
    });
  }, []);
  const dataLoad = () =>{
    setLoading(true);
    getAllEvents().then((response) => {
      event_list = response;
      setTableData(response);
      setLoading(false);
    });
  }
  
  useEffect(() => {
     setInterval(function() {  
         
      event_list.forEach((event) => {
        // if(event['live_streaming_state'] == 'starting'){
          // console.log(event['live_streaming_state']);
          var stID = event['live_streaming_id'];
          getLiveStreamState(stID).then((response) => {
            var stream_resp = JSON.stringify(response.data.live_stream);
            var stream_project_data = {"event_id":event['id'],"streaming_response":stream_resp,"action":response.data.live_stream.state,"stream_id":event['live_streaming_id']};
            if(response.data.live_stream.state != event['live_streaming_state']){
              storeStreamState(stream_project_data).then((response) => {
                if (response.status_code == 1) {
                  Swal.fire({title: 'Success',
                  text: response.message,
                  icon: 'success',
                  timer: 2000});
                  dataLoad();
                } else {
                  Swal.fire({title: 'Warning',
                  text: response.message,
                  icon: 'warning',
                  timer: 2000});
                  
                }
            
              });
            }

          });
        // }
      
   
  })}, 30000);
  },[]);
  const goToStreamPlatform = (data) => {
    window.open("https://cloud.wowza.com/en/manage/live_streams/"+data.live_streaming_id);
  }

  const createStream = (data) => {
    var event_stream_data = '{"live_stream": {"name": "'+data.title+'", "transcoder_type": "transcoded","billing_mode": "pay_as_you_go", "broadcast_location": "us_west_california","encoder": "other_rtmp","delivery_method": "push","aspect_ratio_width": 1920,"aspect_ratio_height": 1080,"disable_authentication": true}}';


    createEventStream(event_stream_data).then((response) => {

      var stream_resp = JSON.stringify(response.data.live_stream);
      
      var stream_project_data = {"event_id":data.id,"streaming_response":stream_resp,"action":"created","stream_id":response.data.live_stream.id,'source_primary_server':response.data.live_stream.source_connection_information.primary_server,'hls_playback_url':response.data.live_stream.player_hls_playback_url,'stream_name':response.data.live_stream.source_connection_information.stream_name};


      storeStreamProjectData(stream_project_data).then((response) => {
        if (response.status_code == 1) {
          Swal.fire({title: 'Success',
          text: response.message,
          icon: 'success',
          timer: 2000});

          dataLoad();
        } else {
          Swal.fire("Warning", " " + response.message, "warning");
        }
    
      });
    
    });
  };

  // const startLiveStreamW = (data) => {
  
  //   var stID = data.live_streaming_id;

  //   startLiveStream(stID).then((response) => {

  //     var stream_resp = JSON.stringify(response.data.live_stream);
  //     var stream_project_data = {"event_id":data.id,"streaming_response":stream_resp,"action":response.data.live_stream.state,"stream_id":data.live_streaming_id};

  //     storeStreamState(stream_project_data).then((response) => {
  //       if (response.status_code == 1) {
  //         Swal.fire({title: 'Success',
  //         text: response.message,
  //         icon: 'success',
  //         timer: 2000});
  //         dataLoad();
  //       } else {
  //         Swal.fire("Warning", " " + response.message, "warning");
  //       }
    
  //     });
    
  //   });
  // };

  // const stopLiveStream = (data) => {
  
  //   var stID = data.live_streaming_id;

  //   endLiveStream(stID).then((response) => {

  //     var stream_resp = JSON.stringify(response.data.live_stream);
  //     var stream_project_data = {"event_id":data.id,"streaming_response":stream_resp,"action":response.data.live_stream.state,"stream_id":data.live_streaming_id};

  //     storeStreamState(stream_project_data).then((response) => {
  //       if (response.status_code == 1) {
  //         Swal.fire({title: 'Success',
  //         text: response.message,
  //         icon: 'success',
  //         timer: 2000});
  //         dataLoad();
  //       } else {
  //         Swal.fire("Warning", " " + response.message, "warning");
  //       }
    
  //     });
    
  //   });
  // };
  // function copyToClipboard(text, title) {
  //   navigator.clipboard.writeText(text);
  //   Swal.fire("Success", " " + t("url_copied"), "success");
  // }

  return (
    <>
      <div className="row">
        <div className="col-8">
          <h3> Events</h3>
          <p style={{ color: "grey" }}>
            Create, edit, and manage the events on site.
          </p>
        </div>
        <div className="col-4">
          <Link to="/new-event">
            <button
              type="button"
              class="btn btn-primary mb-3 d-block  float-right"
            >
              Add New Event
            </button>
          </Link>
        </div>
      </div>

      <section className="users-list-wrapper">
        <div className="users-list-table">
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-content">
                  <div className="card-body">
                    <div className="table-responsive">
                      <Table
                        loading={loading}
                        dataSource={tableData}
                        columns={columns}
                        pagination={{
                          pageSize: 10,
                          showSizeChanger: true,
                          position: ["bottomCenter"],
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
export default withNamespaces()(Event);
