import React, { useState, useEffect, useCallback } from "react";
import {
  storeWeekResource,
  advertisementImgStore,
  getAdvertisements,
  deleteAdvertisement,
} from "../services/AdvertisementAPIServices";
import {
  supportedLanguages
} from "../services/PostAPIServices";

import Swal from "sweetalert2";
import { Table, Modal } from "antd";
import "antd/dist/antd.css";
import Cropper from "react-easy-crop";
import getCroppedImg from "../components/cropImage";
import "../components/cropper.css";
import {  postImageUrl } from "../UrlPaths";
import { withNamespaces } from "react-i18next";

function Advertisement({ t }) {
  const spinner = document.getElementById("spinner");
  const [tableData, setTableData] = useState([]);
  const [resource_type, setResourceType] = useState("picture");
  const [languagesList, setLanguagesList] = useState([]);
  const [language, setLanguage] = useState("english");
  const [adv_id, setResourceId] = useState("0");
  const [modalTitle, setModalTitle] = useState("Add New Image");
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);

  const [uploadedImage, setUploadedImage] = useState("");
  const [img_url, setResourcePath] = useState("");

  const [result, setResult] = useState(null);
  const [srcImg, setSrcImg] = useState(null);
  const [imgModalVisible, setImgModalVisible] = useState(false);
  const [modalImgTitle, setModalImgTitle] = useState("Image Crop");
  const [cropB, setCropB] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [tableCaption, setTableCaption] = useState("English Advertisements");

  const [link_url, setAdvertLink] = useState("");
  const [ratio, setRatio] = useState("");
  const [image_width, setImgWidth] = useState("");
  const [image_height, setImgHeight] = useState("");

  var image64Path = "";

  const columns = [
    {
      key: "id",
      title: "ID",
      dataIndex: "id",
      sortDirections: ["descend"],
      hidden: true,
    },
    {
      key: "placement",
      title: "Placement",
      dataIndex: "placement",
    },
    {
      key: "link_url",
      title: "Advert Link",
      dataIndex: "link_url",
    },
    {
      key: "img_url",
      title: " ",
      render: (record) => {
        return (
          <>
            {record.img_url == "" || record.img_url == null ? (
              <>
                <label>No uploaded image</label>
              </>
            ) : (
              <>
                {" "}
                <img
                  src={`${postImageUrl}` + "/" + record.img_url}
                  className="img-fluid"
                  width={200}
                />
              </>
            )}
          </>
        );
      },
    },
    {
      key: "uploaded_date",
      title: "Last Modified",
      dataIndex: "uploaded_date",
    },
    {
      key: "action",
      title: "Actions",
      render: (record) => {
        return (
          <>
            <div className="flex">
              <button
                className="btn bg-light-info"
                style={{ color: "black" }}
                onClick={() => Edit(record)}
              >
                Upload / <i class="ft-edit"></i>
              </button>
              &nbsp;
              <button
                type="submit"
                className="btn bg-light-danger"
                onClick={() => deleteImage(record)}
              >
                <span for="basic-form-8"> </span>
                <i class="fa fa-remove"></i>
              </button>
            </div>
          </>
        );
      },
    },
  ].filter((item) => !item.hidden);

  useEffect(() => {
    supportedLanguages().then((res) => {
      setLanguagesList(res.languages);
    });
    setLoading(true);

    const data = {
      language,
    };
    getAdvertisements(data).then((response) => {
      spinner.style.display = "none";
      setTableData(response.records);
      setLoading(false);
    });

  }, []);

  function loadTable(){
    setLoading(true);

    const data = {
      language,
    };
    getAdvertisements(data).then((response) => {
      setTableData(response.records);
      setLoading(false);
    });
  }

  const handleSubmit = (e) => {
    e.preventDefault();

    const data = {
      resource_type,
      img_url,
      adv_id,
    };

    storeWeekResource(data).then((response) => {
      if (response.status_code == 1) {
        Swal.fire("Success", " " + response.message, "success");
        setResourceId(0);
        setResourceType("picture");
        setResourcePath("");
        setModalTitle("Add New Resource");
        setVisible(false);
        document.querySelector('#image').value = "";
        const dataload = {
          language,
        };
        setLoading(true);
        getAdvertisements(dataload).then((response) => {
          setTableData(response.records);
          setLoading(false);
        });
      } else {
        Swal.fire("Warning", " " + response.message, "warning");
      }
    });
  };
  const handleImageSubmit = (e) => {
    e.preventDefault();

    if (image64Path == "" || image64Path == null) {
      Swal.fire("Warning", t("crop_image"), "warning");
    } else {
      const data = new FormData();
      data.append("resource", image64Path);

      advertisementImgStore(data).then((response) => {
        if (response.status_code == 1) {
          Swal.fire("Success", t("image_upload_success"), "success");
          setUploadedImage(`${postImageUrl}` + "/" + response.message);
          setResourcePath(response.message);
          setImgModalVisible(false);
        } else {
          Swal.fire("Warning", " " + response.message, "warning");
        }
      });
    }
  };

  const Edit = (data) => {
    setResourceId(data.id);
    if (data.img_url == "" || data.img_url == null) {
      setModalTitle("Upload Advertisement");
    } else {
      setModalTitle("Edit Advertisement");
      setUploadedImage(`${postImageUrl}` + "/" + data.img_url);
    }

    setResourcePath(data.img_url);
    setAdvertLink(data.link_url);
    setRatio(data.image_ratio);
    setImgHeight(data.height);
    setImgWidth(data.length);
    setVisible(true);
  };

  const deleteImage = (data) => {
    const dataDelete = new FormData();
    dataDelete.append("adv_id", data.id);
    deleteAdvertisement(dataDelete).then((response) => {
      if (response.status_code == 1) {
        Swal.fire("Success", " " + response.message, "success");
        const dataload = {
          language,
        };
        setLoading(true);
        getAdvertisements(dataload).then((response) => {
          setTableData(response.records);
          setLoading(false);
        });
      } else {
        Swal.fire("Warning", " " + response.message, "warning");
      }
    });
  };

  const handleImage = async (event) => {
    let img = new Image();
    img.src = window.URL.createObjectURL(event.target.files[0]);
    img.onload = () => {
   
      if (img.width >= image_width && img.height >= image_height) {
        setSrcImg(URL.createObjectURL(event.target.files[0]));
        setResult("");
        setImgModalVisible(true);
        return true;
      } else {
        Swal.fire(
          "Warning",
          `Sorry, this image doesn't look like the size we wanted. It's 
          ${img.width} x ${img.height} but we require at least ${image_width} x ${image_height} image.`,
          "warning"
        );
        return true;
      }
    };
  };

  //----------------- new code ------
  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {

    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const convertBlobToBase64 = (blob) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onerror = reject;
      reader.onload = () => {
        resolve(reader.result);
      };
      reader.readAsDataURL(blob);
    });

  const onUploadCrop = useCallback(
    async (e) => {

      if (croppedAreaPixels) {
        const [croppedBlob, croppedUri] = await getCroppedImg(
          srcImg,
          croppedAreaPixels
        );
        const base64String = await convertBlobToBase64(croppedBlob);

        setResult(base64String);
        image64Path = base64String;
        setTimeout(handleImageSubmit(e), 2500);
      }
    },
    [srcImg, croppedAreaPixels]
  );
  const getLangRelatedData = () => {
    loadTable()
    setTableCaption(language+ " advertisements")
  }

  return (
    <>
      <div className="row">
        <div className="col-12">
          <h3>Advertisements</h3>
          <p style={{ color: "grey" }}>Manage the advertisements on your site</p>
        </div>
      </div>

      <section className="users-list-wrapper">
        <div className="users-list-table">
          <div className="row">
         
          <div className="col-3">
            <label >
                    Advert Language <span style={{ color: "red" }}>*</span>
                  </label>
                    <select
                          id="language"
                          name="language"
                          className="form-control"
                          value={language}
                          onChange={(e) => setLanguage(e.target.value)}
                          
                        >
                          <option value="" disabled>Select Language</option>
                          {languagesList.map((item, i) => {
                            return (
                              <>
                                {item.status == 1 ? (
                                  <option value={item.language_in_english.toLowerCase()} key={i}>
                                    {item.language_in_english}
                                  </option>
                                ) : (
                                  <></>
                                )}
                              </>
                            );
                          })}
                    </select>
                   
                 
            </div>
            <div className="col-1">  
            <label for="title" style={{color:'white'}}>
                        --
                        </label>
                    <button
                      type="submit"
                      id="publishBtn"
                      className="btn btn-warning"
                      onClick={() => getLangRelatedData()} 
                    > Load </button>
            </div>
            <div className="col-7"></div>
            <div className="col-12">
              <div className="card">
                <div className="card-content">
                  <div className="card-body">
                  <center><h5>{tableCaption}</h5></center>
                    <div className="table-responsive">
                     
                      <Table
                        loading={loading}
                        dataSource={tableData}
                        columns={columns}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Modal
        title={modalTitle}
        visible={visible}
        okText="Save"
        onCancel={() => setVisible(false) }
        onOk={(e) => {
          handleSubmit(e);
        }}
      >
        <form
          onSubmit={(e) => {
            handleSubmit(e);
          }}
        >
          <div className="row">
            <div className="col-12">
            <label className="form-label">
          Advert Link<span style={{ color: "red" }}>*</span>
          </label>
          <input
            type="text"
            className="form-control"
            name="title"
            id="title"
            value={link_url}
            onChange={(e) => setAdvertLink(e.target.value)}
            required
          />
            </div>
            <div className="col-12">
              <label className="form-label">
                Image     <span style={{ color: "red" }}>*</span> <small style={{ color: "red" }}></small>
              
              </label>
              <input
                type="file"
                name="image"
                id="image"
                accept="image/*"
                className="form-control"
                onChange={handleImage}
              />
            </div>

            <div className="col-12">
              {uploadedImage && (
                <>
                  <center>
                    <label className="form-label">Uploaded Image</label>
                    <br />
                    <img
                      src={uploadedImage}
                      className="img-fluid"
                      width={300}
                    />
                  </center>
                </>
              )}
            </div>
            <input
              type="hidden"
              className="form-control"
              name="adv_id"
              id="adv_id"
              value={adv_id}
              onChange={(e) => setResourceId(e.target.value)}
              required
            />
          </div>
        </form>
      </Modal>

      <Modal
        title={modalImgTitle}
        maskClosable={false}
        visible={imgModalVisible}
        okText="Upload"
        onCancel={() => setImgModalVisible(false)}
        onOk={(e) => {
          onUploadCrop(e);
        }}
      >
        <div className="container-crop" style={{ height: "450px" }}>
          <div className="container-cropper">
            {srcImg && (
              <>
                <div className="cropper">

                  <Cropper
                    image={srcImg}
                    crop={cropB}
                    zoom={zoom}
                    aspect={`${ratio}`}
                    onCropChange={setCropB}
                    onCropComplete={onCropComplete}
                    onZoomChange={setZoom}
                    showGrid={true}
                    // cropShape={"rect"}
                    disableAutomaticStylesInjection={false}
                  />
                </div>
                <div className="slider">
                  <label>{ratio}</label>
                  <input
                    type="range"
                    value={zoom}
                    min={1}
                    max={3}
                    step={0.1}
                    aria-labelledby="Zoom"
                    onChange={(e) => {
                      setZoom(e.target.value);
                    }}
                    className="zoom-range"
                  />
                </div>
              </>
            )}
          </div>
        </div>
      </Modal>
    </>
  );
}
export default withNamespaces()(Advertisement);
