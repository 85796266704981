import React, { useState, useEffect, useRef,useCallback } from "react";
import { Link, useNavigate } from "react-router-dom";
import { DatePicker } from "antd";
import moment from "moment";
import { APIEndpoint, postImageUrl } from "../UrlPaths";
import Swal from "sweetalert2";


import Cropper from 'react-easy-crop'
import getCroppedImg from "../components/cropImage";
import "../components/cropper.css";

import BundledEditor from "../components/BundledEditor";
import { Select, Modal } from "antd";

import { withNamespaces } from "react-i18next";
import {getSeriesList, seriesPostActions, uploadSeriesMainImage} from "../services/MatchSeriesAPIServices";

function NewSeriesPost({ t }) {

  //  moment.tz.setDefault("Asia/Colombo");
  const inputRef = useRef();
  const series_page_nav = useNavigate();
  const { Option } = Select;
  const spinner = document.getElementById("spinner");
  const [seriesList, setSeriesList] = useState([]);
  const [title, setTitle] = useState("");
  const [series_id, setSeriesId] = useState("");
  const [post_content, setPostContent] = useState("");
  const [postId, setPostId] = useState(0);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [uploadedImage, setUploadedImage] = useState("");
  const [modalTitle, setModalTitle] = useState("Image Crop");
  const [visible, setVisible] = useState(false);
  const [main_image_path, setMainImagePath] = useState("");
  const [publishDate, setPublishDate] = useState(moment());

  const [cropB, setCropB] = useState({ x: 0, y: 0 })
  const [zoom, setZoom] = useState(1)
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [style, setStyle] = useState({  containerStyle: { top: "0",maxWidth:"100%", maxHeight:"100%", overflow: "hidden" },
  mediaStyle: { width: "100%", display: "block" }, 
             });

  var image64Path = "";

  useEffect(() => {
    getSeriesList().then((response) => {
      setSeriesList(response);
      spinner.style.display = "none";
    });

  }, []);

  const editorRef = useRef(null);
  const log = () => {
    if (editorRef.current) {
      setPostContent(editorRef.current.getContent());

    }
  };

  //-------------------------------------------
  //save the image that used to be crop
  const [image, setImage] = useState(null);
  //change the aspect ratio of crop tool as you preferred
  const [crop, setCrop] = useState({ aspect: 16 / 9 });
  //save the resulted image
  const [result, setResult] = useState(null);
  const [srcImg, setSrcImg] = useState(null);

  const handleImage = async (event) => {
    let img = new Image()
    img.src = window.URL.createObjectURL(event.target.files[0])
    img.onload = () => {

        if(img.width >= 800 && img.height >= 450){
          setSrcImg(URL.createObjectURL(event.target.files[0]));
          setVisible(true);
            return true;
        }else{
          Swal.fire("Warning", `Sorry, this image doesn't look like the size we wanted. It's 
          ${img.width} x ${img.height} but we require at least 800 x 450 image.`, "warning");
          return true;
        }
      
    }

   
  };

//----------------- new code ------
  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {

    setCroppedAreaPixels(croppedAreaPixels);
  }, []);
  

   const convertBlobToBase64 = (blob) => new Promise((resolve, reject) => {
    const reader = new FileReader;
    reader.onerror = reject;
    reader.onload = () => {
        resolve(reader.result);
    };
    reader.readAsDataURL(blob);
});

  const onUploadCrop = useCallback(async (e) => {
    if (croppedAreaPixels) {
      const [croppedBlob, croppedUri] = await getCroppedImg(
        srcImg,
        croppedAreaPixels
      );
      const base64String = await convertBlobToBase64(croppedBlob);

      setResult(base64String);
      image64Path = base64String;
      setTimeout(handleSubmit(e), 2500);
     
    }
  }, [srcImg, croppedAreaPixels]);

//----------------------

  useEffect(() => {
    // console.log(replyDataList);
  }, [srcImg]);


  const onChangeStartDate = (date, dateString) => {
    setPublishDate(dateString);
   
  };

  //------------------------------------------

  const handlePostPublish = (e) => {
    e.preventDefault();
    var status = "PUBLISHED";
    const data = {
      title,
      series_id,
      post_content,
      main_image_path,
      postId,
      publishDate,
      status,
    };    

    var validation = true;

    if(validation){
      postNewsDataStore(data);
    }

  };

  const handlePostDraft = (e) => {
    e.preventDefault();
    var status = "DRAFT";
    const data = {
      title,
      series_id,
      post_content,
      main_image_path,
      postId,
      publishDate,
      status,
    };
    var validation = true;

    if(validation){
      postNewsDataStore(data);
    }

  };

  const postNewsDataStore = (data) => {
    setButtonLoading(true);
    document.getElementById("publishBtn").disabled = true;
    seriesPostActions(data).then((response) => {
      if (response.status_code == 1) {
        Swal.fire("Success", "" + response.message, "success");
        setTitle("");
        setPostContent("");
        series_page_nav("/series-news");
        series_page_nav(0);
      } else {
        Swal.fire("Warning", " " + response.message, "warning");
      }
      setButtonLoading(false);
      document.getElementById("publishBtn").disabled = false;
    });
  }


  //---------------------------------------------------------------------

  const example_image_upload_handler = (blobInfo, progress) =>
    new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.withCredentials = false;
      xhr.open("POST", `${APIEndpoint}` + "/uplaodImageFromEditor");

      xhr.upload.onprogress = (e) => {
        progress((e.loaded / e.total) * 100);
      };

      xhr.onload = () => {
        if (xhr.status < 200 || xhr.status >= 300) {
          reject("HTTP Error: " + xhr.status);
          return;
        }

        const json = JSON.parse(xhr.responseText);
        console.log(json);
        // if (!json || typeof json.location != 'string') {
        //   reject('Invalid JSON: ' + xhr.responseText);
        //   return;
        // }

        resolve(`${postImageUrl}` + "/" + json.message);
      };

      xhr.onerror = () => {
        reject(
          "Image upload failed due to a XHR Transport error. Code: " +
            xhr.status
        );
      };

      const formData = new FormData();
      formData.append("file", blobInfo.blob(), blobInfo.filename());

      xhr.send(formData);
    });

  //-----------------------------------------------------------------------------------
  const handleSubmit = (e) => {
    e.preventDefault();

    if (image64Path == "" || image64Path == null) {
      Swal.fire("Warning", t("crop_image"), "warning");
    } else {
    const data = new FormData();
    data.append("file", image64Path);

    uploadSeriesMainImage(data).then((response) => {
      if (response.status_code == 1) {
        Swal.fire("Success", t("image_upload_success"), "success");
        setMainImagePath(response.message);
        setUploadedImage(`${postImageUrl}` + "/" + response.message);
        setVisible(false);
      } else {
        Swal.fire("Warning", " " + response.message, "warning");
      }
    });
  }
  };

  
  const popupDocument = (url) => {
    let win = window.open(
      url,
      null,
      "popup,width=500,height=600,left=0,right=0"
    );
  };


  return (
    <>
      <div className="row">
        <div className="col-8">
          <h3> New Series Post </h3>
        </div>
      </div>
      <section id="action-form-layout">
        <div className="row match-height">
          <div className="col-9">
            <div className="card">
              <div className="card-header">
                <b>
                  <Link to={`/series-news`} style={{float:'left'}}>
                    <i class="ft-chevrons-left"></i>
                    <span for="basic-form-8"> All Series News </span>
                  </Link>
                </b>
              </div>
              <div className="card-content">
                <div className="card-body">
                  <div className="form-row">
                    <div className="col-md-12 col-12">
                      <div className="form-group position-relative">
                        <label for="title">
                          Title <span style={{ color: "red" }}>*</span>
                        </label>
                        <input
                          type="text"
                          id="title"
                          className="form-control"
                          placeholder="Title"
                          name="title"
                          value={title}
                          onChange={(e) => setTitle(e.target.value)}
                          required
                        />
                      </div>
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="col-md-4 col-12">
                      <div className="form-group position-relative">
                        <label for="form-action-3">
                          Series  <span style={{ color: "red" }}>*</span>
                        </label>
                        <select
                          className="form-control"
                          value={series_id}
                          onChange={(e) => setSeriesId(e.target.value)}
                          required
                        >
                          <option value="" disabled>
                            select series
                          </option>
                          {seriesList.map((item, i) => {
                            return (
                              <>
                                  <option value={item.id} key={i}>
                                    {item.name}
                                  </option>
                              </>
                            );
                          })}
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                    <label for="form-action-7">
                      Post Content <span style={{ color: "red" }}>*</span>
                    </label>

                    <BundledEditor
                      onInit={(evt, editor) => (editorRef.current = editor)}
                      onChange={log}
                      // initialValue={post_content}
                      init={{
                        height: 500,
                        menubar: false,
                        plugins: [
                          "advlist",
                          "anchor",
                          "autolink",
                          "image",
                          "link",
                          "lists",
                          "searchreplace",
                          "table",
                          "media",
                          "mediaembed",
                          "wordcount",
                        ],
                        toolbar:
                          "blocks | " +
                          "bold italic forecolor | alignleft aligncenter " +
                          "alignright alignjustify | bullist numlist image | " +
                          "link table media | outdent indent",
                        toolbar_sticky: true,
                        content_style:
                          "body { font-family:Roboto, sans-serif; font-size:16px }",
                        a11y_advanced_options: true,
                        image_advtab: true,
                        image_uploadtab: true,
                        automatic_uploads: true,
                        relative_urls: false,
                        remove_script_host: false,
                        images_upload_handler: example_image_upload_handler,
                        // video_template_callback: function(data) {
                        //   console.log(data.source);
                        //   if (data.source.includes('assets/videos/')) {
                        //     var embedHtml = '<video controls="controls" controlsList="nodownload" width="'+data.width+'" height="' + data.height + '"> <source src="https://admin.ln.archnix.dev/' + data.source + '" type="'+data.sourcemime+'"></video>';
                        //     return embedHtml;
                        //   } else {
                        //     return "";
                        //   }
                        // }
                      }}
                    />
                  </div>
                  <hr />
                </div>
              </div>
            </div>
          </div>
          <div className="col-3">
            <div className="form-group">
              <form
                onSubmit={(e) => {
                  handlePostDraft(e);
                }}
              >
                <button
                  type="submit"
                  style={{
                    border: "none",
                    background: "none",
                    padding: 2,
                    decoration: "underline",
                    color: "blue",
                  }}
                  className="float-left"
                  disabled={buttonLoading}
                >
                  Save to draft
                </button>
              </form>
              <form
                onSubmit={(e) => {
                  handlePostPublish(e);
                }}
              >
                <button
                  type="submit"
                  id="publishBtn"
                  className="btn btn-primary float-right"
                  disabled={buttonLoading}
                >
                  {buttonLoading && (
                    <>
                      <i className="ft-refresh-ccw"> </i>
                      <span>Publishing...</span>
                    </>
                  )}
                  {!buttonLoading && (
                    <>
                      <i className="ft-check-square mr-1"></i>
                      <span> Publish</span>{" "}
                    </>
                  )}
                </button>
              </form>
              {/* </div> */}
            </div>
            <br></br> <br></br> <br></br>
            <div className="row">
            <div className="col-12 col-md-12">
                <div className="form-group">
                  <label for="basic-form-8">
                   Publish Date <span style={{ color: "red" }}>*</span>
                  </label>
                  <DatePicker 
                    onChange={onChangeStartDate}
                    format={'DD/MM/YYYY HH:mm'}
                    disabledDate={(current) => current.valueOf() > Date.now()}
                    defaultValue={publishDate !== ""
                    ? moment(publishDate)
                    : moment()}
                    />
                </div>
              </div>
              <div className="col-12 col-md-12">
                <div className="form-group">
                  <label for="basic-form-8">
                    Main Image <span style={{ color: "red" }}>*</span>
                  </label>
                  {/* <ImageResize /> */}
                  {/* <DropZone/> */}
                  <input
                    type="file"
                    name="document"
                    accept="image/*"
                    max-file-size="1024"
                    className="form-control"
                    // ref={inputRef}
                    required
                    onChange={handleImage}
                    // onChange={(e) => setDocument(inputRef.current.files[0])}
                  />
                  <p class="text-muted mb-0 mt-1 mt-sm-0">
                    <small>*Allowed JPG, PNG or JPEG.</small>
                  </p>
                </div>
              </div>
              <div className="col-12 col-md-12">
                {uploadedImage && (<>
                  <img
                    src={uploadedImage}
                    className="img-fluid"
                    width={200}
                    alt="cropped image"
                  />
                  
                  <button
                  type="button"
                  id="popupBtnId"
                  class="btn btn-info bg-light-info"
                  onClick={() => popupDocument(`${uploadedImage}`)}
                >
                  <i className="ft-eye"></i>
                </button>
                </>)}
              </div>
            </div>
            {/* </div> */}
          </div>
        </div>
      </section>
      <Modal
        title={modalTitle}
        maskClosable={false}
        visible={visible}
        okText="Upload"
        width={750}
        onCancel={() => setVisible(false) /*ResetEditing() */}
        onOk={(e) => /*setVisible(false)*/ {
          onUploadCrop(e);
        }}
      >
     <div className="container-crop">
      <div className="container-cropper">
          {srcImg && (
          <>
          <div className="cropper" >

          <Cropper
            image={srcImg}
            crop={cropB}
            zoom={zoom}
            aspect={16 / 9}
            onCropChange={setCropB}
            onCropComplete={onCropComplete}
            onZoomChange={setZoom}
            // style={style}
            showGrid={true}
            cropShape={'rect'}
            disableAutomaticStylesInjection={false}
          />
      </div>
          <div className="slider">
            <input
              type="range"
              value={zoom}
              min={1}
              max={3}
              step={0.1}
              aria-labelledby="Zoom"
              onChange={(e) => {
                setZoom(e.target.value);
              }}
              className="zoom-range"
            />
          </div>
             {/* start previous code */}
              {/* <ReactCrop
                style={{ maxWidth: "90%" }}
                src={srcImg}
                onImageLoaded={setImage}
                crop={crop}
                ruleOfThirds
                onChange={setCrop}
                onZoomChange={onZoomChange}
              /> */}
              {/* <button
                className="btn btn-warning cropButton"
                 onClick={onUploadCrop}
              >
                Crop
              </button> */}
              {/* end previous code */}
   
      </>  )}
    {/* start previous code */}
          {/* {result && (
            <div>
              <label><b>{t("cropped_image_header")}</b></label><br/>
              <img src={result} alt="cropped image" width={400}/>
            </div>
          )} */}
          {/* end previous code */}
        </div>
        </div>
      </Modal>
    </>
  );
}

export default withNamespaces()(NewSeriesPost);
