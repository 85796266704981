import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { loginAPI } from "../services/AuthAPIServices";
import Swal from "sweetalert2";
import { withNamespaces } from "react-i18next";
import { websiteUrl } from "../UrlPaths";

function Login({ t }) {
  const navigate = useNavigate();
  const [rememberMe, setRememberMe] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const spinner = document.getElementById("spinner");
  spinner.style.display = "none";
  const handleSetRememberMe = () => setRememberMe(!rememberMe);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (email == "" || email == null || password == "" || password == null) {
      Swal.fire("Warning", t("form_validation"), "warning");
    } else {
      const credentials = {
        email,
        password,
      };
      loginAPI(credentials).then((res) => {

        if (res.status_code == 1) {
          localStorage.setItem("win_user", res.data.name);
          localStorage.setItem("win_user_id", res.data.user_id);
          localStorage.setItem("win_img_path", res.data.path);
          localStorage.setItem("win_authenticated", true);
          localStorage.setItem("win_access_token", res.data.access_token);
          localStorage.setItem("win_privilege", res.data.is_admin);
          localStorage.setItem("win_user_role", res.data.user_role);

          //   Swal.fire("success", "Successfully login", "success");
          if(res.data.is_admin == 1){
            if(res.data.user_role == '1'){
              navigate("/dashboard");
              navigate(0);
            }else{
              navigate("/posts");
              navigate(0);
            }
            
          }else{
            window.location.replace(websiteUrl);
          }
         
         
        } else {
          Swal.fire("Warning", "" + res.message, "warning");
        }
      });
    }
  };

  return (
    <>
      <div className="content-wrapper" style={{ background: "#d0d0f2" }}>
        <section id="login" className="auth-height">
          <div className="row full-height-vh m-0">
            <div className="col-12 d-flex align-items-center justify-content-center">
              <div className="card overflow-hidden">
                <div className="card-content">
                
                {/* linear-gradient(to left, #ffffff,#ffffff,#f0f0f5,#e8e8ed,#dedee3,#dedee3,#d7d2cc 30%, #304352 100%) */}
                  <div
                    className="card-body auth-img"
                    // style={{ background: "linear-gradient(to left, rgb(255, 255, 255), rgb(255, 255, 255), rgb(48, 67, 82) 100%)" }}
                  >
                    <div className="row m-0">

                      <div className="col-lg-6 d-none d-lg-flex justify-content-center align-items-center auth-img-bg p-4">
                        <img
                          src="app-assets/img/new_logo_1.png"
                          alt=""
                          className="img-fluid"
                          width="300"
                          height="230"
                        />
                      </div>
                      <div className="col-lg-6 col-12 px-4 py-3">
                        <h4
                          className="mb-2 card-title"
                          
                        >
                          <b>Login</b>
                        </h4>
                        <p>
                          Welcome back, please login to your account.
                        </p>
                        <form
                          id="login-form"
                          onSubmit={(e) => {
                            handleSubmit(e);
                          }}
                        >
                          <input
                            style={{ color: "black" }}
                            type="email"
                            className="form-control mb-3"
                            placeholder="Email"
                            name="email"
                            id="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                          />
                          <input
                           style={{ color: "black" }}
                            type="password"
                            className="form-control mb-2"
                            placeholder="Password"
                            name="password"
                            id="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            required
                          />
                          <div className="d-sm-flex justify-content-between mb-3 font-small-2">
                            <div className="remember-me mb-2 mb-sm-0">
                              <div className="checkbox auth-checkbox">
                                <input
                                  type="checkbox"
                                  id="auth-ligin"
                                  checked={rememberMe}
                                  onChange={handleSetRememberMe}
                                  onClick={handleSetRememberMe}
                                />
                                <label for="auth-ligin">
                                  <span>
                                    Remember Me
                                  </span>
                                </label>
                              </div>
                            </div>
                            <a href="forgot-password">Forgot Password?</a>
                          </div>
                          <div className="d-flex justify-content-between flex-sm-row flex-column">
                            <button type="submit" className="btn btn-primary">
                              Login
                            </button>
                          </div>
                        </form>
                        <hr />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

export default withNamespaces()(Login);
