import React, { useState, useEffect } from "react";
import { getAllCustomers } from "../services/AuthAPIServices";
import { Table, Input, Button } from "antd";
import "antd/dist/antd.css";
import { SearchOutlined } from "@ant-design/icons";
import { CSVLink } from "react-csv";

function Viewers() {
  const spinner = document.getElementById("spinner");
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(false);

  const columns = [
    {
      key: "id",
      title: "ID",
      dataIndex: "id",
      sortDirections: ["descend"],
      hidden: true,
    },
    {
      key: "first_name",
      title: "First Name",
      dataIndex: "first_name",
      filterMode: "tree",
      filterSearch: true,
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              autoFocus
              placeholder="Type title"
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropDown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            ></Input>
            <Button
              onClick={() => {
                confirm();
              }}
              type="primary"
            >
              Search
            </Button>
          </>
        );
      },
      filterIcon: () => {
        return <SearchOutlined />;
      },
      onFilter: (value, record) => {
        return record.first_name.toLowerCase().includes(value.toLowerCase());
      },
    },
    {
      key: "last_name",
      title: "Last Name",
      dataIndex: "last_name",
    },
    {
      key: "email",
      title: "Email",
      dataIndex: "email",
    },
    {
      key: "mobile_no",
      title: "Phone No",
      dataIndex: "mobile_no",
    },
    {
      key: "dob",
      title: "DoB",
      dataIndex: "dob",
    },
    {
      key: "registered_date",
      title: "Registered Date",
      dataIndex: "registered_date",
    },
  ].filter((item) => !item.hidden);

  useEffect(() => {
    setLoading(true);

    getAllCustomers().then((response) => {
     //   console.log(response);
      spinner.style.display = "none";
      setTableData(response.data.records);
      setLoading(false);
    });
  }, []);


  return (
    <>
      <div className="row">
        <div className="col-12">
          <h3> Viewers</h3>
          <p style={{ color: "grey" }}>
          Registered event watchers on the website
          </p>
        </div>
      </div>

      <section className="users-list-wrapper">
        <div className="users-list-table">
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-content">
                  <div className="card-body">
                  <CSVLink
                  filename={"viewers.csv"}
                  data={tableData}
                  onClick={() => {
                    console.log("clicked");
                  }}
                >
                  Download CSV
                </CSVLink>
                    <div className="table-responsive">
                      <Table
                        loading={loading}
                        dataSource={tableData}
                        columns={columns}
                        pagination={{
                          pageSize: 10,
                          showSizeChanger: true,
                          position: ["bottomCenter"],
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
export default Viewers;
