import React, { useState, useEffect,useRef } from "react";
import { getLoggedUserDetails,changePasswordAPI,updateUserProfile} from "../services/AuthAPIServices";
import Swal from "sweetalert2";
import { displayUrl } from '../UrlPaths';
import { withNamespaces } from "react-i18next";
import { Modal,Button } from "antd";

function UserProfile({t}) {
  const inputRef = useRef();
  const user_id = localStorage.getItem("win_user_id");
  const [role_name, setUserRoleName] = useState("");
  const [user_name, setUserName] = useState("");
  const [user_email, setUserEmail] = useState("");
  const [registered_date, setRegisteredDate] = useState("");
  const [old_password, setOldPassword] = useState("");
  const [new_password, setNewPassword] = useState("");
  const [confirmed_new_password, setConfirmedNewPassword] = useState("");
  const spinner = document.getElementById("spinner");
  const img_path = localStorage.getItem("win_img_path");
  const [name, setName] = useState("");
  const [existedImage, setExistedImage] = useState("");
  const [image, setImage] = useState("");
  const [visible, setVisible] = useState(false);
  const [modalTitle, setModalTitle] = useState("User Profile");
  const [buttonLoading, setButtonLoading] = useState(false);

  useEffect(() => {
   dataLoad()
  }, []);

  function dataLoad(){
    const user = {
      user_id,
    };
    getLoggedUserDetails(user).then((response) => {
      setUserRoleName(response.data.role_name);
      setUserName(response.data.name);
      setUserEmail(response.data.email);
      setRegisteredDate(response.data.registered_date);
      setName(response.data.name);
      setExistedImage(response.data.profile_img_path);
      localStorage.setItem("win_img_path", response.data.profile_img_path);
      spinner.style.display = "none";
    });
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    if (
      old_password == "" ||
      old_password == null ||
      new_password != confirmed_new_password ||
      new_password.length < 8
    ) {
      if (old_password == "" || old_password == null) {
        Swal.fire("Warning", t("form_validation"), "warning");
      } else if (new_password.length < 8) {
        Swal.fire("Warning", t("pwd_length"), "warning");
      } else if (new_password != confirmed_new_password) {
        Swal.fire("Warning", t("confirmed_pwd"), "warning");

      }
    } else {
      const reset_data = {
        new_password,
        old_password,
        user_id
      };
      changePasswordAPI(reset_data).then((res) => {
       
        if (res.status_code == 1) {
          Swal.fire("Success", "" + res.message, "success");
          setOldPassword("");
          setNewPassword("");
          setConfirmedNewPassword("");

        } else {
          Swal.fire("Warning", "" + res.message, "warning");
        }
      });
    }
  };

  const handleEditDataForm = () => {
    setModalTitle("Edit User Profile");
    if (image) {
      inputRef.current.value = "";
    }
    setVisible(true);
  };

  const handleSubmitMainData = (e) => {
    const data = new FormData();
    data.append("name", name);
    data.append("profile_image", image);
    setButtonLoading(true);

    updateUserProfile(data).then((response) => {
 
        if (response.status_code == 1) {
            Swal.fire("Success", ""+response.message, "success").then(function() {
              setVisible(false);
              dataLoad()
            });

        } else {
            Swal.fire("Warning", " " + response.message, "warning");
        }
        setButtonLoading(false);
    });

  }

  return (
    <>
      <div class="row">
        <div class="col-12">
          <h4 class="content-header">User Account</h4>
        </div>
      </div>
      <div class="row">
        <div class="col-md-3 mt-3">
          <ul class="nav flex-column nav-pills" id="myTab" role="tablist">
            <li class="nav-item">
              <a
                class="nav-link active"
                id="general-tab"
                data-toggle="tab"
                href="#general"
                role="tab"
                aria-controls="general"
                aria-selected="true"
              >
                <i class="ft-info mr-1 align-middle"></i>
                <span class="align-middle">Info</span>
              </a>
            </li>
            <li class="nav-item">
              <a
                class="nav-link"
                id="change-password-tab"
                data-toggle="tab"
                href="#change-password"
                role="tab"
                aria-controls="change-password"
                aria-selected="false"
              >
                <i class="ft-lock mr-1 align-middle"></i>
                <span class="align-middle">Change Password</span>
              </a>
            </li>
          </ul>
        </div>
        <div class="col-md-9">
          <div class="card">
            <div class="card-content">
              <div class="card-body">
                <div class="tab-content">
                  <div
                    class="tab-pane active"
                    id="general"
                    role="tabpanel"
                    aria-labelledby="general-tab"
                  >
                    <div class="media">
                      <img
                        src={`${displayUrl}/${img_path}`}
                        alt="profile-img"
                        class="rounded mr-3"
                        height="64"
                        width="64"
                      />
                      <div class="media-body">
                        <div class="col-12 d-flex flex-sm-row flex-column justify-content-between px-0">
                          <h4>{user_name}</h4>
                          <h6 style={{ float: 'right' }}><button
                            type="button"
                            className="btn btn-light-info  float-right"
                            onClick={(e) => handleEditDataForm(e)}
                          >
                            <i className="fa fa-edit"></i>
                          </button></h6>
                        </div>
                        <p class="text-muted mb-0  mt-sm-0">{role_name}</p>
                      </div>
                    </div>
                    <hr class="mt-1 mt-sm-1" />
                    <form novalidate>
                      <div class="row">
                        <div class="col-12 form-group">
                          <label for="email">E-mail</label>
                          <div class="controls">
                            <input
                              type="text"
                              id="email"
                              class="form-control"
                              value={user_email}
                              placeholder="E-mail"
                              readOnly
                            />
                          </div>
                        </div>

                        <div class="col-12 form-group">
                          <label for="company">Registered Date</label>
                          <div class="controls">
                            <input
                              type="text"
                              id="company"
                              class="form-control"
                              value={registered_date}
                              placeholder="Date"
                              aria-invalid="false"
                              readOnly
                            />
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>

                  <div
                    class="tab-pane"
                    id="change-password"
                    role="tabpanel"
                    aria-labelledby="change-password-tab"
                  >
                    <form
                      id="pwdchange-form"
                      onSubmit={(e) => {
                        handleSubmit(e);
                      }}
                    >
                      <div class="form-group">
                        <label for="old-password">Old Password <span style={{ color: "red" }}>*</span></label>
                        <div class="controls">
                          <input
                            type="password"
                            id="old-password"
                            class="form-control"
                            placeholder="Old Password"
                            value={old_password}
                            onChange={(e) => setOldPassword(e.target.value)}
                            required
                          />
                        </div>
                      </div>
                      <div class="form-group">
                        <label for="new-password">New Password <span style={{ color: "red" }}>*</span></label>
                        <div class="controls">
                          <input
                            type="password"
                            id="new-password"
                            class="form-control"
                            placeholder="New Password"
                            value={new_password}
                            onChange={(e) => setNewPassword(e.target.value)}
                            required
                          />
                        </div>
                      </div>
                      <div class="form-group">
                        <label for="retype-new-password">
                          Retype New Password <span style={{ color: "red" }}>*</span>
                        </label>
                        <div class="controls">
                          <input
                            type="password"
                            id="retype-new-password"
                            class="form-control"
                            placeholder="New Password"
                            value={confirmed_new_password}
                            onChange={(e) =>
                              setConfirmedNewPassword(e.target.value)
                            }
                            required
                          />
                        </div>
                      </div>
                      <div class="d-flex flex-sm-row flex-column justify-content-end">
                        <button
                          type="submit"
                          class="btn btn-primary mr-sm-2 mb-1"
                        >
                          Save Changes
                        </button>
                        <button type="reset" class="btn btn-secondary mb-1">
                          Cancel
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        maskClosable={false}
        title={modalTitle}
        visible={visible}
        okText="Save"
        onCancel={() => setVisible(false)}
        onOk={(e) => {
          handleSubmitMainData(e);
        }}
        footer={[
          <Button key="cancel" onClick={() => setVisible(false)}>
          Cancel
        </Button>,
          <Button key="submit" type="primary" loading={buttonLoading} onClick={handleSubmitMainData}>
            Save
          </Button>
        
        ]}
      >
        <form
          id="form-new-employee"
          onSubmit={(e) => {
            handleSubmitMainData(e);
          }}
        >
          <div className="form-row">
            <div className="col-md-3 text-center">
              <div className="form-group position-relative">
                <img alt={'profile-image'} className="img-fluid" width={150}
                  src={`${displayUrl}/${existedImage}`} />
              </div>
            </div>
            <div className="col-md-9">
              <div className="form-group position-relative">
                <label>
                NAME WITH INITIALS (WEBSITE DISPLAY NAME) <span style={{ color: "red" }}>*</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder={"Name"}
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  required
                />
              </div>
              <div className="form-group position-relative">
                <label>
                  Replace profile image
                </label>
                <input
                  type="file"
                  name="document"
                  accept="image/*"
                  className="form-control"
                  ref={inputRef}
                  onChange={(e) =>
                    setImage(inputRef.current.files[0])
                  }
                />
              </div>
            </div>
          </div>
        </form>
      </Modal>
    </>
  );
}
export default withNamespaces()(UserProfile);
