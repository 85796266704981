export const GET_MATCH_STATUS = () => {
    return [{ 'id': 'UPCOMING', 'name': 'UPCOMING','color':''},
        { 'id': 'LIVE', 'name': 'LIVE'},
        { 'id': 'STUMPS', 'name': 'STUMPS'},
        { 'id': 'RESULT', 'name': 'RESULT' },
        { 'id': "INNINGS BREAK", 'name': "INNINGS BREAK" },
        { 'id': "MATCH ABANDONED", 'name': "MATCH ABANDONED" },
        { 'id': "MATCH DELAYED", 'name': "MATCH DELAYED" }]
}

export const GET_MATCH_FORMAT = () => {
    return [{ 'id': 'T20', 'name': 'T20'},
        { 'id': 'T20I', 'name': 'T20I'},
        { 'id': 'ODI', 'name': 'ODI'},
        { 'id': 'TEST', 'name': 'TEST' },
        { 'id': 'IPL', 'name': 'IPL' }]
}