import axios from "axios";
import { APIEndpoint } from "../UrlPaths";
const token = localStorage.getItem("win_access_token");


export async function getAllResource(data) {
  try {
    const response = await axios.post(APIEndpoint + "/getAllResource", data, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data.data;
  } catch (error) {
    return error;
  }
}

export async function resourceStore(data) {
  try {
    const response = await axios.post(APIEndpoint + "/resourceStore", data, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data;
  } catch (error) {
    return error;
  }
}
export async function storeWeekResource(data) {
  try {
    const response = await axios.post(APIEndpoint + "/storeWeekResource", data, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data;
  } catch (error) {
    return error;
  }
}

export async function deleteResource(data) {
  try {
    const response = await axios.post(APIEndpoint + "/deleteResource", data, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data;
  } catch (error) {
    return error;
  }
}
