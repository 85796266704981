import axios from "axios";
import { APIEndpoint } from "../UrlPaths";
const token = localStorage.getItem("win_access_token");


export async function getAllNotification() {
  try {
    const response = await axios.get(APIEndpoint + "/get-notifications-history", {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data.data;
  } catch (error) {
    return error;
  }
}

export async function storeNotification(data) {
  try {
    const response = await axios.post(APIEndpoint + "/notifications-create", data, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data;
  } catch (error) {
    return error;
  }
}
