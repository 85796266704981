import { useParams, Link } from "react-router-dom";
import React, { useState, useEffect, useRef } from "react";
import { APIEndpoint, postImageUrl } from "../UrlPaths";
import Swal from "sweetalert2";
import {
  getSinglePostData,
  getPostRevisions,
  revisionRestore
} from "../services/PostAPIServices";
// import CodeDiff from 'react-text-diff';

import axios from "axios";
import $ from "jquery";
// var Diff = require('react-diff');
function PostRevisions() {
  const { id } = useParams();
  const spinner = document.getElementById("spinner");
  const [revisionList, setRevisionList] = useState([]);
  const [title, setTitle] = useState("");
  const [oldStr, setOldStr] = useState("");
  const [newStr, setNewStr] = useState("");
  const [outputFormat, setOutputFormat] = useState("");
  const [selectedVersion, setSelectedVersion] = useState(0);

  useEffect(() => {
    var data = { id };
    getPostRevisions(data).then((response) => {
      console.log(response);
      setRevisionList(response.data.records);
      setNewStr(response.data.latest_post.post_content);
      spinner.style.display = "none";
    });

    getSinglePostData(data).then((response) => {
      setTitle(response.data.title);
    });
  }, []);

  const restoreSubmit = (main_post_id, log_id) => {
    const data = { main_post_id, log_id };

    revisionRestore(data).then((response) => {
      if (response.status_code == 1) {
        Swal.fire("Success", ""+response.message, "success");

      } else {
        Swal.fire("Warning", " " + response.message, "warning");
      }

    });
  };

  const restoreVersionSelection = (main_post_id, log_id) => {
  //  setSelectedVersion(log_id);
  }; 

  function createMarkupOld(post_content) {
    return { __html: post_content };
  }

  function createMarkupNew() {
    return { __html: newStr };
  }

  return (
    <>
      <div className="row">
        <div className="col-8">
          <h3>
            {" "}
            Revisions of <span style={{ color: "blue" }}>{title}</span>
          </h3>
        </div>
      </div>
      <div className="col-12 col-xl-12">
        <div className="card collapse-icon accordion-icon-rotate left">
          <div className="accordion" id="accordionExample3">
            <div className="card-header">
              <b>
                <Link to={`/posts`}>
                  {" "}
                  <i class="ft-chevrons-left"></i>
                  <span for="basic-form-8"> All Posts </span>
                </Link>
              </b>
            </div>
            <div className="card-body">
              {revisionList.map((item, i) => {
                return (
                  <>
                    {" "}
                    <div>
                      <div
                        className="card-header border-bottom pb-3"
                        id={`headingAccordion${item.log_id}`}
                      >
                        <a
                          data-toggle="collapse"
                          href={`#accordion${item.log_id}`}
                          aria-expanded="true"
                          aria-controls={`accordion${item.log_id}`}
                          className="card-title"
                        
                        >
                          <small>
                            {" "}
                            Revision by {item.name} ({item.logged_time})
                          </small>
                        </a>
                        <input type="checkbox" className="float-right" name="vehicle1" value={item.log_id}   onChange={restoreVersionSelection(
                            item.post_id,
                            item.log_id
                          )}/>
                      </div>
                      <div
                        id={`accordion${item.log_id}`}
                        className="collapse"
                        aria-labelledby={`headingAccordion${item.log_id}`}
                        data-parent="#accordionExample3"

                      >
                        <div className="card-body">
                          <div className="row">
                            <div className="col-12">
                              <button
                                type="button"                               
                                id= {`restoreBtn${item.log_id}`}
                                className="btn btn-warning float-right"
                               
                              >
                                Restore Content
                              </button>
                            </div>
                            <div className="col-6">
                              <h6>Old Version</h6>
                              {/* <CodeDiff oldStr={oldStr} newStr={newStr} outputFormat={outputFormat}/>; */}
                              {/* <Diff inputA="gogol" inputB="google" type="chars" /> */}
                              <div
                                className="ck-content"
                                dangerouslySetInnerHTML={createMarkupOld(
                                  item.post_content
                                )}
                              />
                              {}
                            </div>
                            <div className="col-6">
                              <h6>Current Version</h6>
                              <div
                                className="ck-content"
                                dangerouslySetInnerHTML={createMarkupNew()}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default PostRevisions;
