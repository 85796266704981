import React, { useState, useEffect,useRef } from "react";
import {
  getCategories,
  categoryActions,
  categoryStatusChange,
} from "../services/CategoryAPIServices";

import Swal from "sweetalert2";
import { Table, Modal, Input, Button, Switch } from "antd";
import "antd/dist/antd.css";
import {
  SearchOutlined,
  CheckOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import { displayUrl } from "../UrlPaths";

function Category() {
  const inputRef = useRef();
  const spinner = document.getElementById("spinner");
  const [tableData, setTableData] = useState([]);
  const [category_name, setCategoryName] = useState("");
  const [category_name_en, setCategoryNameEng] = useState("");
  const [category_name_sin, setCategoryNameSin] = useState("");
  const [category_name_tm, setCategoryNameTm] = useState("");
  const [description, setDescription] = useState("");
  const [display_order, setDisplayOrder] = useState("");
  const [status, setStatus] = useState("");
  const [slug, setSlug] = useState("");
  const [categoryId, setCategoryId] = useState("0");
  const [is_used_for_menu, setMainMenu] = useState("0");
  const [modalTitle, setModalTitle] = useState("Add New Category");
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [image, setImage] = useState("");
  const [existedImage, setExistedImage] = useState("");
  const [imageLabel, setImageLabel] = useState("Image");

  const columns = [
    {
      key: "id",
      title: "ID",
      dataIndex: "id",
      sortDirections: ["descend"],
      hidden: true,
    },
    {
      key: "category_name",
      title: "Category Name",
      dataIndex: "category_name",
      filterMode: "tree",
      filterSearch: true,
      
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              autoFocus
              placeholder="Type category name"
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropDown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            ></Input>
            <Button
              onClick={() => {
                confirm();
              }}
              type="primary"
            >
              Search
            </Button>
          </>
        );
      },
      filterIcon: () => {
        return <SearchOutlined />;
      },
      onFilter: (value, record) => {
        return record.category_name.toLowerCase().includes(value.toLowerCase());
      },
    },
    {
      key: "display_order",
      title: "Display Order",
      dataIndex: "display_order",
    },
    {
      key: "is_used_for_menu",
      title: "Main Menu Display Status",
      dataIndex: "is_used_for_menu",
    },
    {
      key: "status",
      title: "Activation",
      render: (record) => {
        return (
          <>
            {" "}
            <div className="flex">
              {record.status == "1" ? (
                  <span className="badge bg-light-success">Active</span>
              ) : (
                <span className="badge bg-light-danger">Inactive</span>
              )}
            </div>
          </>
        );
      },
    },
    {
      key: "action",
      title: "Actions",
      render: (record) => {
        return (
          <>
            <div className="flex">
              <button
                className="btn btn-sm bg-light-danger"
                style={{ color: "black" }}
                onClick={() => Edit(record)}
              >
                <i class="ft-edit"></i>
              </button>
            </div>
          </>
        );
      },
    },
  ].filter((item) => !item.hidden);

  useEffect(() => {
    setLoading(true);
    spinner.style.display = "none";
    getCategories().then((response) => {

      
      setTableData(response);
      setLoading(false);
    });
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    if(category_name_en == "" || category_name_en == " " || category_name_en == null){
      Swal.fire("Warning", "Please enter the category name in English language", "warning");
    }else if(category_name_sin == "" || category_name_sin == " " || category_name_sin == null) {
      Swal.fire("Warning", "Please enter the category name in Sinhala language", "warning");

    }else if(category_name_tm == "" || category_name_tm == " " || category_name_tm == null){
      Swal.fire("Warning", "Please enter the category name in Tamil language", "warning");
    }else{
      let category_name = '{"english":"'+category_name_en+'","sinhala":"'+category_name_sin+'","tamil":"'+category_name_tm+'"}';
      // let category_name = JSON.parse(category_name_str);
      const data = new FormData();
      data.append("category_name", category_name);
      data.append("description", description);
      data.append("display_order", display_order);
      data.append("status", status);
      data.append("categoryId", categoryId);
      data.append("is_used_for_menu", is_used_for_menu);
      data.append("slug", slug);
      data.append("image", image);
      // const data = {
      //   category_name,
      //   description,
      //   display_order,
      //   status,
      //   categoryId,
      //   is_used_for_menu,
      //   slug
      // };
  
      categoryActions(data).then((response) => {
        if (response.status_code == 1) {
          Swal.fire("Success", " " + response.message, "success");
          setCategoryId(0);
          setCategoryName("");
          setDescription("");
          setDisplayOrder("");
          setStatus(1);
          setSlug("");
          setImage("");
          inputRef.current.value = "";
          setModalTitle("Add New Category");
          setVisible(false);
  
          setLoading(true);
  
          getCategories().then((response) => {
            setTableData(response);
            setLoading(false);
          });
        } else {
          Swal.fire("Warning", " " + response.message, "warning");
        }
      });
    }

  };

  const handleNewSectorForm = (e) => {
    e.preventDefault();
    setCategoryId(0);
    setCategoryName("");
    setDescription("");
    setDisplayOrder("");
    setCategoryNameEng("");
    setCategoryNameSin("");
    setCategoryNameTm("");
    setMainMenu("0")
    setStatus(1);
    setSlug("");
    if(image){
      inputRef.current.value = "";
    }
    setExistedImage("");
    setImageLabel("Image");
    setModalTitle("Add New Category");
    setVisible(true);
  };
  const Edit = (data) => {
    
    var categoryArr = data.category_name;
    var category_names = categoryArr.split(',');

    setCategoryNameEng(category_names[0].trim());
    setCategoryNameSin(category_names[1].trim());
    setCategoryNameTm(category_names[2].trim());
    setCategoryId(data.id);
    
    setStatus(data.status);
    setDescription(data.description);
    setDisplayOrder(data.display_order);
    setSlug(data.slug);
    setMainMenu(data.is_used_for_menu);
    setExistedImage(data.category_image);
    setImageLabel("Replace Image");
    setModalTitle("Edit Category Details");
    setVisible(true);
  };

  const handleClick = (status, categoryId) => {
    const data = { status, categoryId };

    categoryStatusChange(data).then((response) => {
      if (response.status_code == 1) {
        Swal.fire("Success", "" + response.message, "success");
      } else {
        Swal.fire("Warning", " " + response.message, "warning");
      }

      setLoading(true);

      getCategories().then((response) => {
        setTableData(response);
        setLoading(false);
      });
    });
  };

  const autoFillSlug = () => {
    var categoryInEnglish = category_name_en.replaceAll(" ","-");
    setSlug(categoryInEnglish.toLowerCase());
  };

  return (
    <>
      <div className="row">
        <div className="col-8">
          <h3> Categories</h3>
          <p style={{ color: "grey" }}>
          Create, edit, and manage the categories on your website
          </p>
        </div>
        <div className="col-4">
          <button
            type="button"
            class="btn btn-primary mb-3 d-block  float-right"
            data-toggle="modal"
            data-target="#sectorModal"
            onClick={(e) => handleNewSectorForm(e)}
          >
            Add New
          </button>
        </div>
      </div>

      <section className="users-list-wrapper">
        <div className="users-list-table">
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-content">
                  <div className="card-body">
                    <div className="table-responsive">
                      <Table
                        loading={loading}
                        dataSource={tableData}
                        columns={columns}
                        pagination={{
                          pageSize: 10,
                          showSizeChanger: true,
                          position: ["bottomCenter"],
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Modal
        title={modalTitle}
        visible={visible}
        okText="Save"
        onCancel={() => setVisible(false) /*ResetEditing() */}
        onOk={(e) => /*setVisible(false)*/ {
          handleSubmit(e);
        }}
      >
        <form
          onSubmit={(e) => {
            handleSubmit(e);
          }}
        >
          <div className="row">
          <div className="col-12"> <label className="form-label">
            Category Name
          </label>
          
          </div>
          
          <div className="col-4">
          <label className="form-label" style={{color:"gray"}}>
           In English <span style={{ color: "red" }}>*</span>
          </label>
          <input
            type="text"
            className="form-control"
            name="category_name"
            id="category_name"
            value={category_name_en}
            onChange={(e) => setCategoryNameEng(e.target.value)}
            onKeyUp={autoFillSlug}
            required
          />
          </div>
          <div className="col-4">
          <label className="form-label" style={{color:"gray"}}>
           In Sinhala <span style={{ color: "red" }}>*</span>
          </label>
          <input
            type="text"
            className="form-control"
            name="category_name"
            id="category_name"
            value={category_name_sin}
            onChange={(e) => setCategoryNameSin(e.target.value)}
            required
          />
          </div>
          <div className="col-4">
          <label className="form-label" style={{color:"gray"}}>
           In Tamil <span style={{ color: "red" }}>*</span>
          </label>
          <input
            type="text"
            className="form-control"
            name="category_name"
            id="category_name"
            value={category_name_tm}
            onChange={(e) => setCategoryNameTm(e.target.value)}
            required
          />
          </div>
          <div className="col-12">
          <span className="text-muted"> Slug: {slug}</span>
          </div>
          <div className="col-12">
          <label className="form-label">Description</label>
          <input
            type="text"
            className="form-control"
            name="category_name"
            id="category_name"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
          </div>
          <div className="col-4">
          <label className="form-label">
            Display Order <span style={{ color: "red" }}>*</span>
          </label>
          <input
            type="text"
            min={1}
            className="form-control"
            name="category_name"
            id="category_name"
            value={display_order}
            onChange={(e) => setDisplayOrder(e.target.value)}
            required
          />
          </div>
          <div className="col-8">
          <label className="form-label">
            Main Menu Setup <span style={{ color: "red" }}>*</span>
          </label>
          <select
            className="form-control"
            name="is_used_for_menu"
            id="is_used_for_menu"
            value={is_used_for_menu}
            required
            onChange={(e) => setMainMenu(e.target.value)}
          >
            <option value="1"> Display in main menu</option>
            <option value="0"> Don't display in main menu</option>
          </select>
          </div>
       
          <div className="col-4">
          <label className="form-label">
            Status <span style={{ color: "red" }}>*</span>
          </label>
          <select
            className="form-control"
            name="status"
            id="status"
            value={status}
            required
            onChange={(e) => setStatus(e.target.value)}
          >
            <option value="1"> Active</option>
            <option value="0"> Inactive</option>
          </select>
          </div>
          <div className="col-8">
          <label className="form-label">
            {imageLabel} <span style={{ color: "red" }}>*</span>
          </label>
          <input
                  type="file"
                  name="document"
                  accept="image/svg+xml"
                  className="form-control"
                  ref={inputRef}
                  onChange={(e) =>
                    setImage(inputRef.current.files[0])
                  }
                />
                <small className="text-muted">*only use the svg format image</small>
          </div>
         
         {existedImage ? (<>
          <div className="col-12 text-center">
            <label className="form-label">Current Image</label><br/>
          <img alt={'category-image'} className="img-fluid" width={100} src={`${displayUrl}/${existedImage}`}/>
          </div>
         </>):(<></>)}
         
          </div>
        </form>
      </Modal>
    </>
  );
}
export default Category;
