import React, { useState, useEffect } from "react";
import { getAllPostComments } from "../services/PostAPIServices";
import { useLocation } from "react-router-dom";
import { Table, Input } from "antd";
import "antd/dist/antd.css";
import { displayUrl } from "../UrlPaths";
import moment from "moment";

function Comment() {

  const location = useLocation()
  const { commentSearch, searchString } = location.state ?? {}

  const spinner = document.getElementById("spinner");
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchedText, setSearchedText] = useState("");

  const columns = [
    {
      key: "title",
      title: "Comments",
      filteredValue: [searchedText],
      render: (record) => {
        return (
          <>
            <div className="d-flex">

              {record.is_post == 1 ? (<> <strong>{record.title}</strong> <span style={{ marginLeft: 'auto' }}> <i class="fa fa-comments font-medium-5"></i> <span className={`notification badge badge-pill ${record.children.length > 0 ? 'badge-danger' : 'badge-success'} `}>{record.children.length}</span></span></>) : (<>
                {record.parent_comment_id == null ? (<></>) : (<>&emsp;&emsp;</>)}
                <div className="d-flex mr-2">
                  <img
                    src={`${displayUrl}/${record.profile_img_path}`}
                    className="avatar mr-2"
                    alt="avatar"
                    width="30"
                    height="30"
                  />
                  <strong>{record.name} {record.lname}</strong>
                </div>

                <span> {record.comment}</span>
                <span className="text-muted" style={{ marginLeft: 'auto' }}>{moment(record.created_at).format("DD/MM/YYYY HH:mm")}</span>
              </>)}


            </div>
          </>
        );
      },
      onFilter: (value, record) => {
        return (
          record.title.toLowerCase().includes(value.toLowerCase())
        );
      },
    },
  ].filter((item) => !item.hidden);

  useEffect(() => {

    if (searchString != "" && searchString != null) {
      setSearchedText(searchString);
    } else {
      setSearchedText("");
    }

    setLoading(true);

    const filters = { postId: "" };
    getAllPostComments(filters).then((response) => {

      spinner.style.display = "none";
      setTableData(response);
      setLoading(false);
    });
  }, []);


  return (
    <>
      <div className="row">
        <div className="col-12">
          <h3>All Comments</h3>
        </div>
      </div>

      <section className="users-list-wrapper">
        <div className="users-list-table">
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-content">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-md-6"></div>
                      <div className="col-md-6">
                        <Input.Search
                          placeholder="Search here..."
                          style={{ margin: 10 }}
                          value={searchedText}
                          onSearch={(value) => {
                            setSearchedText(value);
                          }}
                          onChange={(e) => {
                            setSearchedText(e.target.value);
                          }}
                        />
                      </div>
                    </div>
                    <div className="table-responsive">
                      <Table
                        loading={loading}
                        dataSource={tableData}
                        columns={columns}
                        pagination={{
                          pageSize: 10,
                          showSizeChanger: true,
                          position: ["bottomCenter"],
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
export default Comment;
